/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { TextField } from '@rmwc/textfield';
import { Checkbox } from '@rmwc/checkbox';
import { Select } from '@rmwc/select';
import { toFloat } from 'validator';
import { Icon } from '@rmwc/icon';
import {
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { isEqual } from 'lodash';

import '@material/checkbox/dist/mdc.checkbox.css';
import '@material/form-field/dist/mdc.form-field.css';
import '@material/tab-bar/dist/mdc.tab-bar.css';
import '@material/tab-indicator/dist/mdc.tab-indicator.css';
import '@material/tab-scroller/dist/mdc.tab-scroller.css';
import '@material/tab/dist/mdc.tab.css';
import '@rmwc/data-table/data-table.css';
import '@rmwc/icon/icon.css';

import '@material/select/dist/mdc.select.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/list/dist/mdc.list.css';
import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';
import '@material/icon-button/dist/mdc.icon-button.css';

import { buttonColors } from '../calendar/EmployeeCalendar';
import noop from '../utils/noop';
import moment, { periodOverlapInHours } from '../utils/moment';

import UserProductCollection from '../models/product/UserProductCollection';
import WorkTaskEntry from './WorkTaskEntry';
import WorkOrderTripIcon from './work-order-trip-icon';
import HourlyTimelogRow from './hourly-timelog/hourly-timelog-row';
import HourlyTimelogDialog from './hourly-timelog/hourly-timelog-dialog';

import ProductDialog from '../shared/ProductDialog';
import CustomTimeField from '../shared/CustomTimeField';
import truncate from '../utils/truncate';
// import TimelogEntry from './TimelogEntry';

const handleKeyDown = (e) => {
  e.target.style.height = 'inherit';
  e.target.style.height = `${e.target.scrollHeight}px`;
  // In case you have a limitation
  // e.target.style.height = `${Math.min(e.target.scrollHeight, limit)}px`;
};

const handleEnter = (e) => {
  if (e.key === 'Enter') {
    document.activeElement.blur();
  }
};

const roundToTwoDecimals = (number) => (Math.round(number * 100) / 100);

// NOTE: This was copypasted and pruned from work-order-trip-icon.js
const getOverlappingTrips = (item, onGoingTrip, endedTrips) => {
  const rowDate = item.date.format('YYYY-MM-DD');
  // Trips that overlap with the row date
  const overlappingTrips = [];
  const onGoingTripStartDate = onGoingTrip?.startTrip.date.format('YYYY-MM-DD');
  // Sort the ended trips in a ascending order by date/time
  // Why are we sorting here, in the row's render? Does endedTrips combine every ended trip the user has ever made?
  endedTrips.sort((a, b) => a.endTrip.toDate - b.endTrip.toDate || moment(a.endTrip.toTime) - moment(b.endTrip.toTime));

  endedTrips.forEach((trip) => {
    const startDate = trip.startTrip.date.format('YYYY-MM-DD');
    const endDate = trip.endTrip.toDate.format('YYYY-MM-DD');

    // Get trips that overlap with the current row date (inclusive)
    if (item.date.isBetween(startDate, endDate, null, '[]')) {
      overlappingTrips.push(trip);
    }
  });

  if (onGoingTripStartDate && rowDate >= onGoingTripStartDate) {
    overlappingTrips.push(onGoingTrip);
  }

  return overlappingTrips;
};

// Mildly adapted from ChatGPT 4o 11.6.2024 code
const findGreatestRelevantWorkHour = (workHours, targetDate) => {
  let result = null;

  workHours.forEach((wh) => {
    // First if: check if it is a matching date in the past that has an ID
    if (wh.date.isSameOrBefore(targetDate) && wh.date.day() === targetDate.day() && wh.id) {
      // Second if: check if a match is greater than the current match
      if (!result || wh.date.isAfter(result.date)) {
        result = wh;
      }
    }
  });

  return result;
};

@inject('timelogStore', 't', 'uiStore', 'workOrderTripStore', 'employerContextStore', 'globalSnackbarStore')
@observer
class TimelogRow extends Component {
  ALLOWANCES = {
    none: this.props.t('timelog.allowances.none'),
    full: this.props.t('timelog.allowances.1'),
    half: this.props.t('timelog.allowances.2'),
    meal: this.props.t('timelog.allowances.3'),
    two_meals: this.props.t('timelog.allowances.4'),
    full_plus_2: this.props.t('timelog.allowances.5'),
    full_plus_10: this.props.t('timelog.allowances.6'),
    full_half: this.props.t('timelog.allowances.7'),
  };

  constructor(props) {
    super(props);

    const { uiStore: { currentUser: { accountId } } } = this.props;

    // Hardcoded for accounts 1, 3 and 10
    if (accountId === 1 || accountId === 10 || accountId === 3) {
      this.ALLOWANCES.se = this.props.t('timelog.allowances.8');
      this.ALLOWANCES.se_double = this.props.t('timelog.allowances.12');
      this.ALLOWANCES.fr = this.props.t('timelog.allowances.10');
      this.ALLOWANCES.pt = this.props.t('timelog.allowances.11');
    }

    // Hardcoded for accounts 3, 7, 10 and 42 (Sweden allowance)
    if (accountId === 7 || accountId === 10 || accountId === 3 || accountId === 42) {
      this.ALLOWANCES.se = this.props.t('timelog.allowances.8');
    }

    // Hardcoded for accounts 3, 10 and 42 (Spain allowance)
    if (accountId === 3 || accountId === 10 || accountId === 42) {
      this.ALLOWANCES.es = this.props.t('timelog.allowances.9');
    }

    const timeNormal = Number(props.item.timeNormal) || 0;
    const time50 = Number(props.item.time50) || 0;
    const time100 = Number(props.item.time100) || 0;
    const travelTime = Number(props.item.travelTime) || 0;

    const calcBankHours = timeNormal + time50 + time100 + travelTime;

    this.state = {
      removeTripButton: false,
      // currentTrip: this.getCurrentTrip(props.endedTrips)
      tripListOpen: false,
      startDates: [],
      endDates: [],
      itemBankHours: calcBankHours,
      productDialogOpen: false,
      openUserProductCollection: null,
      disableAllowanceAutofillDialogOpen: false,
      allowanceAutofillReminderDialogOpen: false,
      workTaskEntryDialogOpen: false,
    };
  }

  componentDidMount() {
    const { item } = this.props;
    const driveTimeCombined = Number(item.driveTime) + Number(item.driveTime50) + Number(item.driveTime100);

    this.setState({
      ...item,
      driveTimeCombined: driveTimeCombined !== 0 ? driveTimeCombined : '',
    });
  }

  // A hack to render a new allowance when it is autofilled by saving a work order trip (only happens after mount by default)
  // Should probably change the structure of the app in general so that all state attributes are derived from the prop, not just this
  componentDidUpdate(prevProps) {
    const { item } = this.props;
    if (!isEqual(item, prevProps.item)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ ...item });
    }
  }

  getWorktimeCells() {
    const { uiStore: { currentUser: { accountId } } } = this.props;

    return (
      <>
        <div
          style={{
            padding: 0,
            width: '30%',
            display: 'flex',
            justifyContent: 'center',
            height: '44px',
          }}
        >
          {this.addHourField('from')}

          <div style={{ alignSelf: 'end' }}>
            <div style={{ height: '30px' }}>
              -
            </div>
          </div>

          {this.addHourField('to')}
        </div>

        {this.addDecimalField('timeNormal')}
        {accountId !== 59 && this.addDecimalField('time50')}
        {accountId !== 59 && this.addDecimalField('time100')}
      </>
    );
  }

  getAllowances() {
    const {
      uiStore: { currentUser: { accountInfo: { workOrderTripsEnabled } } },
      item,
      disabled,
      onGoingTrip,
      endedTrips,
      toggleTripDialog,
      openTripDisabledDialog,
    } = this.props;

    if (workOrderTripsEnabled) {
      return (
        <>
          <WorkOrderTripIcon
            item={item}
            disabled={disabled}
            onGoingTrip={onGoingTrip}
            endedTrips={endedTrips}
            toggleTripDialog={toggleTripDialog}
            openTripDisabledDialog={openTripDisabledDialog}
          />
          {this.addValueList('allowance')}
        </>
      );
    }

    return (
      <>
        {this.addValueList('allowance')}
      </>
    );
  }

  getTravelCells() {
    // TODO: Hardcoded to account id 2, kmsService instead of kmsTrailer
    const { props: { uiStore: { currentUser: { accountId } } } } = this;
    if (accountId === 2) {
      return (
        <>
          {this.addDecimalField('travelTime')}
          {this.addDecimalField('driveTime')}
          {this.addKmsRoute('kms')}
          {this.addDecimalField('kmsPerson', {
            inputMode: 'numeric', max: 9999, pattern: '[0-9]*', step: 0,
          })}
          {this.addDecimalField('kmsService', {
            inputMode: 'numeric', max: 9999, pattern: '[0-9]*', step: 0,
          })}
        </>
      );
    }
    // TODO: Hardcoded to account id 1, custom driveTimeField (50% and 100% drive times) + addKmsRoute
    if (accountId === 1) {
      return (
        <>
          {this.addDecimalField('travelTime')}
          {this.addDriveTimeField()}
          {this.addKmsRoute('kms')}
          {this.addDecimalField('kmsPerson', {
            inputMode: 'numeric', max: 9999, pattern: '[0-9]*', step: 0,
          })}
          {this.addDecimalField('kmsService', {
            inputMode: 'numeric', max: 9999, pattern: '[0-9]*', step: 0,
          })}
        </>
      );
    }
    // TODO: Hardcoded to account id 3, 7 and 10 (work hour/kms trips)
    if (accountId === 7 || accountId === 3) {
      return (
        <>
          {this.addDecimalField('travelTime')}
          {this.addKmsRoute('kms')}
          {this.addDecimalField('kmsService', {
            inputMode: 'numeric', max: 9999, pattern: '[0-9]*', step: 0,
          })}
        </>
      );
    }
    if (accountId === 10) {
      return (
        <>
          {this.addDecimalField('travelTime')}
          {this.addKmsRoute('kms')}
          {this.addDriveTimeField()}
        </>
      );
    }
    if (accountId === 41) {
      return (
        <>
          {this.addDecimalField('travelTime')}
          {this.addKmsRoute('kms')}
          {this.addDecimalField('kmsPerson', {
            inputMode: 'numeric', max: 9999, pattern: '[0-9]*', step: 0,
          })}
          {this.addDecimalField('kmsTrailer', {
            inputMode: 'numeric', max: 9999, pattern: '[0-9]*', step: 0,
          })}
        </>
      );
    }
    if (accountId === 49) {
      return (
        <>
          {this.addDecimalField('travelTime')}
          {this.addKmsRoute('kms')}
          {this.addDecimalField('kmsPerson', {
            inputMode: 'numeric', max: 9999, pattern: '[0-9]*', step: 0,
          })}
          {this.addDecimalField('kmsTrailer', {
            inputMode: 'numeric', max: 9999, pattern: '[0-9]*', step: 0,
          })}
          {this.addDecimalField('kmsService', {
            inputMode: 'numeric', max: 9999, pattern: '[0-9]*', step: 0,
          })}
        </>
      );
    }
    if (accountId === 42 || accountId === 6) {
      return (
        <>
          {this.addDecimalField('travelTime')}
          {this.addKmsRoute('kms')}
          {this.addDecimalField('kmsPerson', {
            inputMode: 'numeric', max: 9999, pattern: '[0-9]*', step: 0,
          })}
          {this.addDecimalField('kmsService', {
            inputMode: 'numeric', max: 9999, pattern: '[0-9]*', step: 0,
          })}
        </>
      );
    }
    if (accountId === 43) {
      return (
        <>
          {this.addDecimalField('travelTime')}
          {this.addDecimalField('driveTime')}
          {this.addKmsRoute('kms')}
          {this.addDecimalField('kmsPerson', {
            inputMode: 'numeric', max: 9999, pattern: '[0-9]*', step: 0,
          })}
          {this.addDecimalField('kmsTrailer', {
            inputMode: 'numeric', max: 9999, pattern: '[0-9]*', step: 0,
          })}
          {this.addDecimalField('kmsHeavyLoad', {
            inputMode: 'numeric', max: 9999, pattern: '[0-9]*', step: 0,
          })}
        </>
      );
    }
    if (accountId === 58) {
      return (
        <>
          {this.addDecimalField('travelTime')}
          {this.addDecimalField('kmsService', {
            inputMode: 'numeric', max: 9999, pattern: '[0-9]*', step: 0,
          })}
          {this.addDecimalField('driveTime')}
          {this.addKmsRoute('kms')}
          {this.addDecimalField('kmsPerson', {
            inputMode: 'numeric', max: 9999, pattern: '[0-9]*', step: 0,
          })}
          {this.addDecimalField('kmsHeavyLoad', {
            inputMode: 'numeric', max: 9999, pattern: '[0-9]*', step: 0,
          })}
        </>
      );
    }
    if (accountId === 59) {
      return (
        <>
          {this.addDecimalField('kmsService', {
            inputMode: 'numeric', max: 9999, pattern: '[0-9]*', step: 0,
          })}
          {this.addKmsRoute('kms')}
          {this.addDecimalField('travelTime')}
        </>
      );
    }
    return (
      <>
        {this.addDecimalField('travelTime')}
        {this.addDecimalField('kmsService', {
          inputMode: 'numeric', max: 9999, pattern: '[0-9]*', step: 0,
        })}
        {this.addKmsRoute('kms')}
        {this.addDecimalField('kmsPerson', {
          inputMode: 'numeric', max: 9999, pattern: '[0-9]*', step: 0,
        })}
      </>
    );
  }

  getExtrasCells() {
    const { props: { uiStore: { currentUser: { accountId } } } } = this;
    // TODO: Hardcoded to account id 2
    if (accountId === 2) {
      return (
        <>
          {this.addDecimalField('sundayWork')}
          {this.addDecimalField('weeklyRest')}
          {this.addDecimalField('eveningShift')}
          {this.addDecimalField('nightShift')}
          {this.addDecimalField('conditionCompensation')}
          {this.addDecimalField('midweekHolidayCompensation')}
        </>
      );
    }
    if (accountId === 7 || accountId === 10) {
      return (
        <>
          {this.addDecimalField('sundayWork')}
          {this.addDecimalField('conditionCompensation')}
          {this.addDecimalField('weeklyRest')}
          {this.addDecimalField('eveningShift')}
          {this.addDecimalField('nightShift')}
          {this.addCheckboxField('emergencyWork')}
          {this.addDecimalField('miningCompensation')}
          {this.addDecimalField('midweekHolidayCompensation')}
        </>
      );
    }
    if (accountId === 43) {
      return (
        <>
          {this.addDecimalField('sundayWork')}
          {this.addDecimalField('weeklyRest')}
          {this.addCheckboxField('emergencyWork')}
          {this.addDecimalField('midweekHolidayCompensation')}
        </>
      );
    }
    if (accountId === 49 || accountId === 159 || accountId === 3 || accountId === 192 || accountId === 93) {
      return (
        <>
          {this.addDecimalField('sundayWork')}
          {this.addDecimalField('conditionCompensation')}
          {this.addDecimalField('weeklyRest')}
          {this.addDecimalField('eveningShift')}
          {this.addDecimalField('nightShift')}
          {this.addCheckboxField('emergencyWork')}
          {this.addDecimalField('midweekHolidayCompensation')}
        </>
      );
    }
    if (accountId === 58) {
      return (
        <>
          {this.addDecimalField('sundayWork')}
          {this.addDecimalField('conditionCompensation')}
          {this.addDecimalField('conditionCompensationTwo')}
          {this.addDecimalField('conditionCompensationThree')}
          {this.addDecimalField('conditionCompensationFour')}
          {this.addDecimalField('weeklyRest')}
          {this.addDecimalField('eveningShift')}
          {this.addDecimalField('nightShift')}
          {this.addCheckboxField('emergencyWork')}
          {this.addDecimalField('midweekHolidayCompensation')}
        </>
      );
    }
    return (
      <>
        {this.addDecimalField('sundayWork')}
        {this.addDecimalField('weeklyRest')}
        {this.addDecimalField('eveningShift')}
        {this.addDecimalField('nightShift')}
        {this.addCheckboxField('emergencyWork')}
        {this.addDecimalField('midweekHolidayCompensation')}
      </>
    );
  }

  getDescriptionCells() {
    return (
      <>
        {this.addTextAreaField('description')}
      </>
    );
  }

  getProducts() {
    const { workOrder, item } = this.props;

    if (workOrder.workHourType === 'hourly') {
      return (
        <div
          style={{
            textAlign: 'center',
            width: '100%',
            margin: 0,
            padding: '0 10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            fontSize: '14px',
          }}
        >
          <div>
            {item.workTaskEntries.map((workTaskEntry) => (
              this.renderWorkTaskEntryProducts(workTaskEntry)
            ))}
            {this.renderWorkTaskEntryProducts()}
          </div>
        </div>
      );
    }

    return this.renderProducts();
  }

  getWorkTaskEntries() {
    const {
      item,
      showDraftsOnly,
      workOrder,
      activeTab,
      employerAcceptance,
      disabled,
      employerUpdateMode,
    } = this.props;
    const { workTaskEntryDialogOpen, workTaskEntries } = this.state;

    // A stupid hack
    if (!workTaskEntries) {
      return null;
    }

    const sortedWorkTaskEntries = [...workTaskEntries].sort((a, b) => WorkTaskEntry.sortByTime(a, b));

    if (sortedWorkTaskEntries.length === 0 && showDraftsOnly) {
      return null;
    }

    return (
      <div style={{ width: '82.5%' }}>
        {/* Optimize: this needs to be rendered only once per TimelogView */}
        <HourlyTimelogDialog
          dialogOpen={workTaskEntryDialogOpen}
          workOrder={workOrder}
          date={item.date}
          hourlyLogRows={sortedWorkTaskEntries}
          closeDialog={this.toggleWorkTaskEntryDialog}
          createHourlyRow={this.createWorkTaskEntry}
          editHourlyRow={this.editHourlyRow}
          deleteHourlyRow={this.deleteHourlyRow}
          item={item}
          employerUpdateMode={employerUpdateMode}
        />
        {sortedWorkTaskEntries.length > 0 && (
          <div>
            {sortedWorkTaskEntries.map((workTaskEntry, index) => (
              <HourlyTimelogRow
                delete={this.deleteWorkHour}
                item={workTaskEntry}
                date={item.date}
                key={workTaskEntry.id || 'creating'}
                toggleDialogOpen={this.toggleWorkTaskEntryDialog}
                activeTab={activeTab}
                employerAcceptance={employerAcceptance}
                dayStatus={item.status}
                disabled={disabled}
                isFirstRow={index === 0}
              />
            ))}
            <div style={{ width: '100%', paddingBottom: '10px' }} />
          </div>
        )}

        {sortedWorkTaskEntries.length === 0 && (
          <div
            style={{
              display: 'flex', fontSize: '.875rem', paddingTop: '15px', paddingBottom: '15px', cursor: 'pointer',
            }}
            onClick={() => !disabled && this.toggleWorkTaskEntryDialog()}
            role="button"
          >
            <div style={{ color: 'gray', paddingLeft: '10px' }}>
              Lisää tunnit
            </div>
          </div>
        )}
      </div>
    );
  }

  editHourlyRow = (row) => {
    const { timelogStore, item, uiStore: { currentUser: { accountId } } } = this.props;

    if (accountId === 1 || accountId === 10) {
      this.distributeDriveTime(this.state.driveTimeCombined);
    }

    timelogStore.updateWorkTaskEntry(item, row);
    this.rowUpdateCallback();
  }

  deleteHourlyRow = (deletingWorkTaskEntry) => {
    const { timelogStore, item, uiStore: { currentUser: { accountId } } } = this.props;

    this.setState((prevState) => ({
      workTaskEntries: prevState.workTaskEntries.filter((row) => row.id !== deletingWorkTaskEntry.id),
    }));
    if (deletingWorkTaskEntry.id !== null) {
      timelogStore.deleteWorkTaskEntry(item, deletingWorkTaskEntry);

      if (accountId === 1 || accountId === 10) {
        this.distributeDriveTime(this.state.driveTimeCombined);
      }
    } else {
      console.error('Tried to delete but row not found');
    }
  }

  // HANDLE PROPS INSTEAD OF STATE HERE + SUB-COMPONENTS
  createWorkTaskEntry = (entry) => {
    const { timelogStore, item, uiStore: { currentUser: { accountId } } } = this.props;
    item.workTaskEntries.push(entry);

    if (accountId === 1 || accountId === 10) {
      this.distributeDriveTime(this.state.driveTimeCombined);
    }

    timelogStore.updateTimelogEntry(item).then(() => {
      // We need to update the rows so that they have IDs for potential deletion from the database
      this.setState({ workTaskEntries: item.workTaskEntries });
      this.rowUpdateCallback();
    });
  }

  doOnBlur = (attr, value, event) => {
    const { item, timelogStore } = this.props;
    const { props: { uiStore: { currentUser: { accountId, workHourBank } } } } = this;

    if (this.state[attr] !== item[attr] && event.target.validity.valid) {
      if (attr === 'timeNormal' || attr === 'time50' || attr === 'time100' || attr === 'travelTime') {
        // First deduct the old value (item[attr]), then add the new value (state[attr])
        // The old value might not exist if the timelog was just created so we default it to 0
        const oldValue = item[attr] || 0;
        const newCalcAmount = (workHourBank.calcAmountToAdd - oldValue) + this.state[attr];
        workHourBank.changeAttribute('calcAmountToAdd', newCalcAmount);
      }

      item.changeAttribute(attr, value);

      // Split driveTime into normal driveTime, 50% driveTime and 100% driveTime in the background
      // TODO: Hardcoded to account id 1 (and 10 for testing)
      if (accountId === 1 || accountId === 10) {
        if (attr === 'driveTime') {
          this.distributeDriveTime(value);
        } else {
          this.distributeDriveTime(this.state.driveTimeCombined);
        }
      }

      timelogStore.updateTimelogEntry(item);
      this.rowUpdateCallback();
    }
  }

  doOnBlurForHours = (attr, value, event) => {
    const {
      item,
      // workOrder,
      timelogStore,
      uiStore: { currentUser: { accountId, workHourBank } },
    } = this.props;

    if (this.state.from != null && this.state.to != null) {
      const timeFrom = moment(this.state.from, 'HH:mm');
      const timeTo = moment(this.state.to, 'HH:mm');
      let time;

      // Evening + night shift autofill
      if (accountId === 49 && timeFrom && timeTo) {
        // Evening shift
        const eveningShiftStart = moment('19:00', 'HH:mm');
        const eveningShiftEnd = moment('22:00', 'HH:mm');
        // Night shift, assuming that night shifts are inputted into the day it starts from
        const nightShiftStart = moment('22:00', 'HH:mm');
        const nightShiftEnd = moment('06:00', 'HH:mm').add(1, 'days');

        let timeToNight;
        // If obvious night shift (e.g. 22:00-16:00) or otherwise overlaps with night shift (e.g. 00:00-08:00)
        if ((timeTo < timeFrom && this.state.from > moment('00:00', 'HH:mm').format('HH:mm'))) {
          // Add a day for accurate comparison: time is actually on the next day
          timeToNight = moment(timeTo).add(1, 'days');
        }
        const eveningShiftHours = periodOverlapInHours(eveningShiftStart, eveningShiftEnd, timeFrom, timeToNight || timeTo);
        item.changeAttribute('eveningShift', eveningShiftHours === 0 ? null : eveningShiftHours);

        const nightShiftHours = periodOverlapInHours(nightShiftStart, nightShiftEnd, timeFrom, timeToNight || timeTo);
        item.changeAttribute('nightShift', nightShiftHours === 0 ? null : nightShiftHours);

        console.error(`${eveningShiftHours}, ${nightShiftHours}`);
      }

      // Night shifts
      if (timeFrom > timeTo) {
        const timeToMidnight = moment('24:00', 'H:m').diff(timeFrom) / 3600000;
        const timeFromMidnight = timeTo.diff(moment('00:00', 'H:m')) / 3600000;
        time = timeToMidnight + timeFromMidnight;
      } else {
        time = timeTo.diff(timeFrom) / 3600000;
      }

      // Account 59 doesn't have 50% or 100%
      // SPECIAL ACCOUNT 59 AUTOFILL START
      if (accountId === 59 && timeFrom && timeTo) {
        item.changeAttribute('timeNormal', time);

        const timeNormal = Number(item.timeNormal) || 0;
        const travelTime = Number(item.travelTime) || 0;

        const newHourAmount = timeNormal + travelTime;
        if (this.state.itemBankHours !== newHourAmount) {
          const newCalcAmount = workHourBank.calcAmountToAdd - this.state.itemBankHours + newHourAmount;
          workHourBank.changeAttribute('calcAmountToAdd', newCalcAmount);
        }

        if (this.state[attr] !== item[attr] && event.target.validity.valid) {
          item.changeAttribute(attr, value);
          timelogStore.updateTimelogEntry(item);
          this.rowUpdateCallback();
        }

        // Hack: update the state to ensure re-render
        this.setState({
          ...item,
        });

        return;
      }
      // SPECIAL ACCOUNT 59 AUTOFILL END

      const weekday = this.state.date.weekday();

      // Mon-Fri
      if (weekday < 5) {
        // Max 8 normal, max 2 50%, the rest is 100%
        if (time > 8) {
          item.changeAttribute('timeNormal', 8);
          const leftoverTime = time - 8;
          if (leftoverTime > 2) {
            // Fill both time50 and time100 appropriately
            item.changeAttribute('time50', 2);
            item.changeAttribute('time100', roundToTwoDecimals(leftoverTime - 2));
          } else {
            // Not enough time for time100, only fill time50
            item.changeAttribute('time50', roundToTwoDecimals(leftoverTime));
            item.changeAttribute('time100', null);
          }
        } else {
          // Enough time only for timeNormal, reset time50 and time100 too
          item.changeAttribute('timeNormal', roundToTwoDecimals(time));
          item.changeAttribute('time50', null);
          item.changeAttribute('time100', null);
        }
        // Sat
      } else if (weekday === 5) {
        // No normals, max 8 50%, the rest is 100%
        if (time > 8) {
          item.changeAttribute('time50', 8);
          item.changeAttribute('time100', roundToTwoDecimals(time - 8));
        } else {
          item.changeAttribute('time50', roundToTwoDecimals(time));
          item.changeAttribute('time100', null);
        }
        // Sun
      } else {
        // if (accountId === 42 || accountId === 3 || accountId === 10) {

        // CHECK THIS LOGIC, CALCULATING MONDAY-SUNDAY MIGHT NOT BE CORRECT
        // IS IT NORMAL TIMES OR ALSO 50% AND 100%?

        //   // Autofill weekly rest for Sundays if total weekly hours are 40 or over and Sundays have hours
        //   const mondayToSunday = [];
        //   const monday = item.date.clone().subtract(6, 'days');

        //   // Find work hours between this Sunday and Monday (ChatGPT)
        //   // eslint-disable-next-line no-restricted-syntax
        //   for (const workHour of workOrder.workHours) {
        //     if (workHour.date.isSameOrAfter(monday) && workHour.date.isBefore(item.date)) {
        //       mondayToSunday.push(workHour);
        //     }
        //     // Optimization: break if the date is past the target date, since the array is sorted
        //     if (workHour.date.isSameOrAfter(item.date)) {
        //       break;
        //     }
        //   }

        //   const preSundayHoursSum = mondayToSunday.reduce((preSundayHours, workHour) => preSundayHours + (workHour.timeNormal || 0) + (workHour.time50 || 0) + (workHour.time100 || 0), 0);

        //   if (time > 0 && preSundayHoursSum + time >= 40) {
        //     item.changeAttribute('weeklyRest', roundToTwoDecimals(time));
        //   } else if (time === 0) {
        //     item.changeAttribute('weeklyRest', null);
        //   }
        // }

        // Everything to 100% and sundayWork
        item.changeAttribute('time50', null);
        item.changeAttribute('time100', roundToTwoDecimals(time === 0 ? null : time));
        item.changeAttribute('sundayWork', roundToTwoDecimals(time === 0 ? null : time));
      }

      // TODO: Hardcoded to account 1 (and 10 for testing)
      if (accountId === 1 || accountId === 10) {
        const combinedDriveTime = Number(item.driveTime) + Number(item.driveTime50) + Number(item.driveTime100);
        this.distributeDriveTime(combinedDriveTime);
      }
    }

    const timeNormal = Number(item.timeNormal) || 0;
    const time50 = Number(item.time50) || 0;
    const time100 = Number(item.time100) || 0;
    const travelTime = Number(item.travelTime) || 0;

    const newHourAmount = timeNormal + time50 + time100 + travelTime;
    if (this.state.itemBankHours !== newHourAmount) {
      const newCalcAmount = workHourBank.calcAmountToAdd - this.state.itemBankHours + newHourAmount;
      workHourBank.changeAttribute('calcAmountToAdd', newCalcAmount);
    }

    if (this.state[attr] !== item[attr] && event.target.validity.valid) {
      item.changeAttribute(attr, value);
      timelogStore.updateTimelogEntry(item);
      this.rowUpdateCallback();
    }

    // Hack: update the state to ensure re-render
    this.setState({
      ...item,
    });
  }

  doOnChange = (attr, value, event) => {
    if (this.state[attr] !== value && event.target.validity.valid) {
      this.setState({ [attr]: value });
    }
  }

  openProductDialog = (userProductCollection, workTaskEntry = null) => {
    const { item } = this.props; // uiStore: { currentUser }

    // const foundUserProductCollections = currentUser.userProductCollections.filter((userProductCollection) => userProductCollection.workOrderId === item.workOrderId && userProductCollection.date.toISOString() === item.date.toISOString());
    // // User product collection where either:
    // // 1. workTaskEntryId matches the clicked workTaskEntry (opening specific work task entry's user product collection)
    // // 2. workTaskEntryId and clicked workTaskEntry's id are both falsy (opening the day's generic user product collection that isn't under any work task entry)
    // const matchingUserProductCollection = foundUserProductCollections.find((collection) => collection.workTaskEntryId === workTaskEntry?.id || (!collection.workTaskEntryId && !workTaskEntry?.id));

    let userProductCollectionToBeOpened = userProductCollection;
    if (!userProductCollectionToBeOpened) {
      // No match, initializing an empty UserProductCollection
      userProductCollectionToBeOpened = new UserProductCollection({
        workOrderId: item.workOrderId,
        workTaskEntryId: workTaskEntry?.id || null,
        date: item.date,
        userProducts: [],
        files: [],
      });
    }

    this.setState({
      productDialogOpen: true,
      clickedWorkTaskEntry: workTaskEntry,
      openUserProductCollection: userProductCollectionToBeOpened,
    });
  }

  closeProductDialog = () => {
    this.setState({
      openUserProductCollection: null,
      productDialogOpen: false,
    });
  }

  toggleWorkTaskEntryDialog = () => {
    const { openDialogCallback } = this.props;
    this.setState((prevState) => ({
      workTaskEntryDialogOpen: !prevState.workTaskEntryDialogOpen,
    }), () => {
      // At the time of writing this is only used to toggle the employer update dialog's title and actions (they overlap with workTaskEntryDialog in iOS Safari)
      if (openDialogCallback) {
        openDialogCallback();
      }
    });
  }

  toggleRowActions = (close) => {
    const {
      workOrder: { workHours },
      item,
      showRowActions,
      closeRowActions,
    } = this.props;

    const copyOptions = [];

    if (!item.id && !close) {
      const dateFormat = 'YYYY-MM-DD';
      const desiredDate = moment(item.date).subtract(1, 'days');
      const desiredDateFormatted = desiredDate.format(dateFormat);
      const desiredWeekDay = desiredDate.day();

      // OPTIMIZE: Instead of searching through every workHour object, filter created ones in TimelogView, maybe
      // Needs to be updated as new work hours are created/updated, though
      const greatestRelevantWorkHour = findGreatestRelevantWorkHour(workHours, item.date);
      const foundAbsolutePreviousWorkHour = workHours.find((workHour) => workHour.date.format(dateFormat) === desiredDateFormatted && workHour.id);

      let absolutePreviousCopyOption = null;
      let greatestRelevantCopyOption = null;
      if (foundAbsolutePreviousWorkHour) {
        absolutePreviousCopyOption = {
          target: item,
          original: foundAbsolutePreviousWorkHour,
          label: 'Kopioi rivi edelliseltä päivältä',
        };
      }

      if (greatestRelevantWorkHour) {
        greatestRelevantCopyOption = {
          target: item,
          original: greatestRelevantWorkHour,
          label: `Kopioi rivi päivältä: ${greatestRelevantWorkHour.date.format('dd DD.MM.')}`,
        };
      }

      if (desiredWeekDay === 5 || desiredWeekDay === 6) {
        // Saturday and Sunday: greatest relevant first, then absolute previous
        if (greatestRelevantCopyOption) {
          copyOptions.push(greatestRelevantCopyOption);
        }
        if (absolutePreviousCopyOption) {
          copyOptions.push(absolutePreviousCopyOption);
        }
      } else {
        // Mon-Fri, absolute previous first, then greatest relevant
        if (absolutePreviousCopyOption) {
          copyOptions.push(absolutePreviousCopyOption);
        }
        if (greatestRelevantCopyOption) {
          copyOptions.push(greatestRelevantCopyOption);
        }
      }
    }

    if (close || (copyOptions.length > 0 && showRowActions)) {
      showRowActions(item, copyOptions);
    } else if (closeRowActions) {
      closeRowActions();
    }
  }

  rowUpdateCallback() {
    const { relayUpdatedWorkHours, workOrder, employerContextTripId } = this.props;

    // In employer editing context (during acceptance), we need to tell the wrapper dialog that work hours have been updated to render the correct action buttons
    if (relayUpdatedWorkHours) {
      const updatedWorkHours = workOrder.workHours.filter((item) => (item.updatedByEmployer));
      relayUpdatedWorkHours(workOrder, updatedWorkHours, employerContextTripId);
    }
  }

  addDecimalField(attr, ctx = {}) {
    const {
      activeTab,
      disabled,
      uiStore: { currentUser: { accountId, accountInfo } },
    } = this.props;

    const {
      from,
      to,
      timeNormal,
      time50,
      time100,
    } = this.state;

    let shouldWarn = false;
    const shouldCloseRowActions = attr !== 'timeNormal' && attr !== 'time50' && attr !== 'time100';

    // Hardcoded to accounts 42, 3 and 10 to highlight potentially undesired lunch breaks
    if (accountId === 42 || accountId === 3 || accountId === 10) {
      const timeFrom = moment(from, 'H:m');
      const timeTo = moment(to, 'H:m');
      let totalTime;

      // Should be moved to utils
      if (from && to && timeFrom > timeTo) {
        // Night shifts
        const timeToMidnight = moment('24:00', 'H:m').diff(timeFrom) / 3600000;
        const timeFromMidnight = timeTo.diff(moment('00:00', 'H:m')) / 3600000;
        totalTime = timeToMidnight + timeFromMidnight;
      } else {
        totalTime = timeTo.diff(timeFrom) / 3600000;
      }

      if ((attr === 'timeNormal' || attr === 'time50' || attr === 'time100') && (from && from >= '06:30' && from <= '07:30') && (Number((timeNormal + time50 + time100)) === 8.5) && totalTime === 8.5) {
        // Add the warning class (text color) to the last field with hours
        if (timeNormal && !time50 && !time100 && attr === 'timeNormal') {
          shouldWarn = true;
        } else if (time50 && !time100 && attr === 'time50') {
          shouldWarn = true;
        } else if (time100 && attr === 'time100') {
          shouldWarn = true;
        }
      }
    }

    return (
      <div
        className={`${((activeTab === 1 && (accountId === 43 || accountId === 1 || accountId === 58)) || activeTab === 3) ? 'mobile-column' : ''}`}
        style={{ padding: 0, textAlign: 'center', width: '16.5%' }}
      >
        <TextField {...{
          className: shouldWarn ? 'pk-number-field-warn pk-number-field' : 'pk-number-field',
          onBlur: (event) => {
            this.doOnBlur(attr, event.target.value, event);
          },
          onChange: noop,
          onInput: (event) => {
            let newValue = event.target.value.replace(/[.,]{1,}/, '.');

            if (newValue.substr(-1) !== '.' && newValue !== '') {
              newValue = toFloat(newValue);
              if (newValue >= (ctx.max || 24)) {
                return;
              }
            }

            if ((!Number.isNaN(newValue) || newValue === '')) {
              this.doOnChange(attr, newValue, event);
            }
          },
          onFocus: () => accountInfo.workHourCopyingEnabled && this.toggleRowActions(shouldCloseRowActions),
          disabled,
          pattern: '[0-9.,]*',
          rootProps: { ripple: false },
          style: {
            height: '44px',
            margin: 0,
            minWidth: '40px',
            padding: 0,
            width: '60px',
            backgroundColor: 'var(--mdc-theme-secondary)',
          },
          // theme: ['textPrimaryOnDark', 'secondaryBg'],
          type: 'text',
          onKeyPress: handleEnter,
          value: (this.state != null && this.state[attr] != null && !Number.isNaN(this.state[attr])) ? this.state[attr] : '',
          ...ctx,
        }}
        />

        {this.renderEmployeeVersionValue(attr)}
      </div>
    );
  }

  addKmsRoute(attr, ctx = {}) {
    const {
      item,
      toggleRouteDialog,
      uiStore: { currentUser: { accountId } },
      disabled,
      workOrder,
    } = this.props;

    let kmsType;
    if (attr === 'driveTime') {
      kmsType = 'drive_time';
    } else {
      kmsType = attr;
    }

    return (
      <>
        <div
          className={accountId === 58 && 'resized-mobile-kms-route-cell'}
          style={{
            padding: (accountId !== 43 && accountId !== 1 && accountId !== 58) ? 0 : '0 0.5rem',
            textAlign: 'center',
            // width: accountId !== 58 ? '16.5%' : '75px',
            width: '16.5%',
            cursor: 'pointer',
          }}
          // eslint-disable-next-line no-confusing-arrow
          onClick={() => !disabled ? toggleRouteDialog(item, kmsType, workOrder) : null}
          role="button"
        >
          <TextField {...{
            className: 'pk-number-field',
            disabled: true,
            pattern: '[0-9.,]*',
            rootProps: { ripple: false },
            style: {
              height: '44px',
              margin: 0,
              minWidth: '40px',
              padding: 0,
              width: '60px',
              backgroundColor: 'var(--mdc-theme-secondary)',
              color: 'white !important',
              WebkitTextFillColor: 'white',
            },
            // theme: ['textPrimaryOnDark', 'secondaryBg'],
            type: 'text',
            // We need to use props here because TimelogRoute dialog updates the prop directly, without touching the state here
            value: item && item[attr] ? item[attr] : '',
            ...ctx,
          }}
          />
          {this.renderEmployeeVersionValue(attr)}
        </div>
      </>
    );
  }

  addDriveTimeField(ctx = {}) {
    const {
      uiStore: { currentUser: { accountId } },
      disabled,
    } = this.props;

    return (
      <div
        className={`${(accountId === 43 || accountId === 1 || accountId === 58) ? 'mobile-column' : ''}`}
        style={{ padding: 0, textAlign: 'center', width: '16.5%' }}
      >
        <TextField {...{
          className: 'pk-number-field',
          // onClick: () => this.toggleRouteDialog(null, 'kms'),
          onBlur: (event) => {
            this.doOnBlurDriveTime('driveTime', event.target.value, event);
          },
          onChange: noop,
          onInput: (event) => {
            let value = event.target.value.replace(/[.,]{1,}/, '.');

            if (value.substr(-1) !== '.' && value !== '') {
              value = toFloat(value);
              if (value >= (ctx.max || 24)) {
                return;
              }
            }

            if ((!Number.isNaN(value) || value === '')) {
              this.doOnChange('driveTime', value, event);
              this.setState({ driveTimeCombined: value });
            }
          },
          disabled,
          pattern: '[0-9.,]*',
          rootProps: { ripple: false },
          style: {
            height: '44px',
            margin: 0,
            minWidth: '40px',
            padding: 0,
            width: '60px',
          },
          theme: ['textPrimaryOnDark', 'secondaryBg'],
          type: 'text',
          value: (this.state != null && this.state.driveTimeCombined != null && !Number.isNaN(this.state.driveTimeCombined)) ? this.state.driveTimeCombined : '',
          onFocus: () => this.toggleRowActions(true),
          ...ctx,
        }}
        />

        {this.renderEmployeeVersionValue('driveTimeCombined')}
      </div>
    );
  }

  addValueList(attr, ctx) {
    const {
      timelogStore,
      item,
      endedTrips,
      onGoingTrip,
      uiStore: { currentUser: { accountInfo } },
      allowanceAutofillReminder,
    } = this.props;

    return (
      <div
        className="allowance-column"
        // style={{ padding: 0, textAlign: 'center', width: '82.5%' }}
        style={{ padding: 0, textAlign: 'center', width: '66%' }}
      >
        <Select
          {...{
            className: 'mdc-theme--text-primary-on-dark pk-select',
            disabled: this.props.disabled,
            onBlur: (event) => {
              const { target: { value, validity: { valid } } } = event;

              // The values are always valid?
              if (value === '' || !valid) {
                return;
              }

              if (accountInfo.workOrderTripsEnabled) {
                // REFACTOR THIS: Stupid how the overlapping trips are searched everywhere instead of being found only once and then used as a prop
                const foundOnGoingTrip = (onGoingTrip && onGoingTrip.startTrip.date <= item.date && (onGoingTrip.workOrders.length === 0 || onGoingTrip.workOrders.map((wo) => wo.id).includes(item.workOrderId))) ? onGoingTrip : null;
                const relevantOverlappingTrips = [];
                const otherWoOverlappingTrips = [];

                endedTrips.forEach((endedTrip) => {
                  const dateOverlap = item.date.isBetween(endedTrip.startTrip?.date, endedTrip.endTrip?.toDate, 'day', []);
                  const workOrderRelevance = endedTrip.workOrders.length === 0 || endedTrip.workOrders.map((wo) => wo.id).includes(item.workOrderId);

                  if (dateOverlap && workOrderRelevance) {
                    relevantOverlappingTrips.push(endedTrip);
                  } else if (dateOverlap) {
                    otherWoOverlappingTrips.push(endedTrip);
                  }
                });

                const overlappingTrips = foundOnGoingTrip ? [foundOnGoingTrip] : relevantOverlappingTrips;

                if (overlappingTrips && overlappingTrips.find((trip) => trip.allowanceAutofillEnabled) && value !== 'full') {
                  // Trip exists and autofill is enabled: ask if the user wants to review the trip or disable autofill
                  this.setState({
                    disableAllowanceAutofillDialogOpen: true,
                    foundOverlappingTrips: overlappingTrips,
                    editingAllowanceValue: value,
                  });
                  return;
                }

                // If trip does not exists and work order trips are enabled, we ask if the user wants to create a trip or not
                // They may turn off this reminder for the salary period + work order (AllowanceAutofillReminder table)
                if ((!overlappingTrips || overlappingTrips.length === 0) && otherWoOverlappingTrips.length === 0 && allowanceAutofillReminder !== false) {
                  this.setState({
                    allowanceAutofillReminderDialogOpen: true,
                    editingAllowanceValue: value,
                  });
                  return;
                }
              }

              if (value !== item[attr] && valid) {
                item.changeAttribute(attr, event.target.value);
                timelogStore.updateTimelogEntry(item);
                this.rowUpdateCallback();
              }
            },
            onChange: (event) => {
              if (event.target.value === '') {
                return;
              }
              if (item[attr] !== event.target.value && event.target.validity.valid) {
                event.target.blur();
              }
            },
            options: this.ALLOWANCES,
            placeholder: this.props.t('timelog.allowances.placeholder'),
            rootProps: { ripple: false },
            // theme: ['textPrimaryOnDark', 'secondaryBg'],
            style: {
              backgroundColor: 'var(--mdc-theme-secondary)', color: 'white', padding: '0', borderRadius: '0',
            },
            // Need to use the prop here because the value list doesn't update the state, only the prop through changeAttribute + timelogStore.updateTimelogEntry
            // This whole prop-state relationship is a mess
            value: item && item[attr] ? item[attr] : '',
            ...ctx,
          }}
        />

        {this.renderEmployeeVersionValue(attr, 'timelog.attributes')}
      </div>
    );
  }

  // TODO: Refactor this and doOnBlur when you have more time
  doOnBlurDriveTime(attr, value, event) {
    const { item, timelogStore } = this.props;

    if (this.state[attr] !== item[attr] && event.target.validity.valid) {
      item.changeAttribute(attr, value);

      // Split driveTime into normal driveTime, 50% driveTime and 100% driveTime in the background
      if (attr === 'driveTime') {
        this.distributeDriveTime(value);
      } else {
        this.distributeDriveTime(this.state.driveTimeCombined);
      }

      timelogStore.updateTimelogEntry(item);
      this.rowUpdateCallback();
    } else if (value === '') {
      this.distributeDriveTime('');
    }
  }

  distributeDriveTime(distributableTime) {
    const { uiStore: { currentUser: { accountId } }, item } = this.props;

    let timeNormal = 0;
    if (item.workTaskEntries && item.workTaskEntries.length > 0) {
      item.workTaskEntries.forEach((workTaskEntry) => {
        timeNormal += workTaskEntry.timeNormal;
      });
    } else if (item.timeNormal) {
      timeNormal = item.timeNormal;
    }

    // For deleting
    if (Number(distributableTime) === 0 || distributableTime === '' || !distributableTime) {
      item.changeAttribute('driveTime', null);
      item.changeAttribute('driveTime50', null);
      item.changeAttribute('driveTime100', null);
      return;
    }

    const weekday = item.date.weekday();

    if (weekday === 5 && (accountId === 1 || accountId === 10)) {
      // Saturday
      item.changeAttribute('driveTime', null);
      item.changeAttribute('driveTime50', distributableTime);
      item.changeAttribute('driveTime100', null);
      return;
    }
    if (weekday === 6 && (accountId === 1 || accountId === 10)) {
      // Sunday
      item.changeAttribute('driveTime', null);
      item.changeAttribute('driveTime50', null);
      item.changeAttribute('driveTime100', distributableTime);
      return;
    }

    if (item.time100 > 0) {
      item.changeAttribute('driveTime', null);
      item.changeAttribute('driveTime50', null);
      item.changeAttribute('driveTime100', distributableTime);
      return;
    }

    if (item.time50 === 1) {
      item.changeAttribute('driveTime', null);
      item.changeAttribute('driveTime50', 1);
      item.changeAttribute('driveTime100', distributableTime - 1);
      return;
    }
    if (item.time50 === 2) {
      item.changeAttribute('driveTime', null);
      item.changeAttribute('driveTime50', null);
      item.changeAttribute('driveTime100', distributableTime);
      return;
    }

    // Drive time normal
    // Above or equal to 8
    if (timeNormal >= 8) {
      if (distributableTime > 2) {
        item.changeAttribute('driveTime', null);
        item.changeAttribute('driveTime50', 2);
        item.changeAttribute('driveTime100', distributableTime - 2);
      } else {
        item.changeAttribute('driveTime', null);
        item.changeAttribute('driveTime50', distributableTime);
        item.changeAttribute('driveTime100');
      }
      // Below 8
    } else {
      // Effectively replaces a null state.timeNormal with 0
      const maxNormal = 8 - timeNormal;
      let leftOver = 0;

      if (distributableTime >= maxNormal) {
        item.changeAttribute('driveTime', maxNormal);
        leftOver = distributableTime - maxNormal;
        if (leftOver > 2) {
          item.changeAttribute('driveTime50', 2);
          item.changeAttribute('driveTime100', leftOver - 2);
        } else {
          if (leftOver !== 0) {
            item.changeAttribute('driveTime50', leftOver);
          } else {
            // Do not save zeroes
            item.changeAttribute('driveTime50', null);
          }
          item.changeAttribute('driveTime100', null);
        }
      } else {
        item.changeAttribute('driveTime', distributableTime);
      }
    }
  }

  addCheckboxField(attr) {
    const {
      item,
      activeTab,
      disabled,
      uiStore: { currentUser: { accountId } },
    } = this.props;

    return (
      <div
        className={`${((activeTab === 1 && (accountId === 43 || accountId === 1 || accountId === 58)) || activeTab === 3) ? 'mobile-column' : ''}`}
        style={{
          padding: 0,
          textAlign: 'center',
          alignSelf: 'center',
          width: '16.5%',
        }}
      >
        <Checkbox {...{
          checked: item[attr] != null ? item[attr] : false,
          className: 'border-on-dark',
          disabled,
          style: {
            marginLeft: 0,
            marginRight: 0,
            height: '44px',
            margin: 0,
            minWidth: '40px',
            padding: 0,
          },
          onChange: (event) => {
            const { timelogStore } = this.props;
            const value = event.target.checked;
            if (item[attr] !== value && event.target.validity.valid) {
              item.changeAttribute(attr, value);
              timelogStore.updateTimelogEntry(item);
              this.rowUpdateCallback();
            }
            this.toggleRowActions(true);
          },
        }}
        />
      </div>
    );
  }

  addTextAreaField(attr) {
    const { disabled } = this.props;
    return (
      <div
        style={{
          padding: 0,
          textAlign: 'center',
          width: '100%',
        }}
      >
        <TextField {...{
          className: 'no-resize',
          disabled,
          onClick: handleKeyDown,
          onKeyDown: handleKeyDown,
          onBlur: (event) => {
            this.doOnBlur(attr, event.target.value, event);
          },
          onChange: (event) => {
            this.doOnChange(attr, event.target.value, event);
            handleKeyDown(event);
          },
          rootProps: { ripple: false },
          style: {
            minHeight: '44px',
            margin: 0,
            padding: 0,
            width: '100%',
          },
          textarea: true,
          theme: ['textPrimaryOnDark', 'secondaryBg'],
          value: (this.state != null && this.state[attr] != null && !Number.isNaN(this.state[attr])) ? this.state[attr] : '',
          onFocus: () => this.toggleRowActions(true),
        }}
        />
      </div>
    );
  }

  addHourField(attr) {
    const { disabled, uiStore: { currentUser: { accountInfo } } } = this.props;

    return (
      <div>
        <div
          className="pk-time-field mdc-text-field mdc-text-field--upgraded mdc-text-field--no-label"
          style={{
            height: '44px',
            width: '45px',
            margin: 0,
            padding: 0,
            backgroundColor: 'var(--mdc-theme-secondary)',
            color: 'white',
          }}
        >
          <CustomTimeField
            value={this.state[attr] || '--:--'}
            attr={attr}
            doOnChange={this.doOnChange}
            doOnBlur={this.doOnBlurForHours}
            handleEnter={handleEnter}
            disabled={disabled}
            doOnFocus={accountInfo.workHourCopyingEnabled && this.toggleRowActions}
          />
        </div>

        {this.renderEmployeeVersionValue(attr)}
      </div>
    );
  }

  updateTimelogAllowance(value, autofill = null) {
    const {
      timelogStore,
      item,
      workOrder,
      periodId,
    } = this.props;

    item.changeAttribute('allowance', value);
    // Currently this is used to disable the reminder (autofill value is "false")
    if (autofill === false || autofill === true) {
      timelogStore.createAllowanceAutofillReminder(periodId, workOrder.id, autofill);
    }
    timelogStore.updateTimelogEntry(item);
    this.rowUpdateCallback();
  }

  versionsDifferent() {
    const { item: currentVersion, item: { latestEmployeeVersion } } = this.props;
    const irrelevantProperties = ['createdAt', 'updatedAt', 'status', 'employerUpdateComment', 'latestEmployeeVersion'];

    const cleanedCurrentVersion = { ...currentVersion };
    const cleanedLatestEmployeeVersion = { ...latestEmployeeVersion };

    irrelevantProperties.forEach((property) => {
      delete cleanedCurrentVersion[property];
      delete cleanedLatestEmployeeVersion[property];
    });

    return Object.keys(cleanedLatestEmployeeVersion).length > 0 && !isEqual(cleanedCurrentVersion, cleanedLatestEmployeeVersion);
  }

  renderEmployeeVersionValue(attr, translationPrefix = null) {
    const {
      t,
      employerUpdateMode,
      item,
      item: { latestEmployeeVersion },
    } = this.props;

    if (latestEmployeeVersion && latestEmployeeVersion[attr] !== item[attr] && ((latestEmployeeVersion.updatedAt !== item.updatedAt) || employerUpdateMode)) { // && latestEmployeeVersion.userId !== currentUser.id
      return (
        <div className="work-hour-old-value">
          {(translationPrefix && latestEmployeeVersion[attr]) ? t(`${translationPrefix}.${latestEmployeeVersion[attr]}`) : (latestEmployeeVersion[attr] || '?')}
        </div>
      );
    }
    return null;
  }

  // renderConfirmAllowanceEditDialog
  renderDisableAllowanceAutofillDialog() {
    const {
      item,
      workOrderTripStore,
      workOrder,
      toggleTripDialog,
      onGoingTrip,
      endedTrips,
      uiStore: { mobileMode, currentUser },
      employerContextStore: { currentEmployeeId },
    } = this.props;
    const { disableAllowanceAutofillDialogOpen, foundOverlappingTrips, editingAllowanceValue } = this.state;

    const overlappingTrips = getOverlappingTrips(item, onGoingTrip, endedTrips);

    return (
      <Dialog
        className="mui-dialog-background"
        fullScreen={false}
        open={disableAllowanceAutofillDialogOpen}
        // This onClose is triggered when clicking outside the dialog to close it
        onClose={() => this.setState({
          disableAllowanceAutofillDialogOpen: false,
          foundOverlappingTrips: null,
        })}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="responsive-dialog-title-button-only">
          <IconButton
            aria-label="close"
            onClick={() => this.setState({
              disableAllowanceAutofillDialogOpen: false,
              foundOverlappingTrips: null,
            })}
          >
            <Icon icon="close" role="button" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Päivärahat täytetään automaattisesti kun annat työmatkatiedot.
          </DialogContentText>
        </DialogContent>
        <DialogActions className={mobileMode ? 'responsive-dialog-stacked-action-buttons' : ''}>
          <Button
            className="employee-accept-button"
            onClick={() => (
              this.setState({
                disableAllowanceAutofillDialogOpen: false,
              }, () => (
                // NOTE: This logic is incomplete, there might be several trips. But as a quick solution, we only open the first one
                toggleTripDialog(foundOverlappingTrips[0], item.date, overlappingTrips)
              ))
            )}
          >
            Tarkista matkatiedot
          </Button>

          <Button
            onClick={() => this.setState({
              disableAllowanceAutofillDialogOpen: false,
            }, () => {
              workOrderTripStore.saveWithAllowanceAutofill(foundOverlappingTrips, workOrder.id, false, currentEmployeeId, currentUser);
              this.updateTimelogAllowance(editingAllowanceValue);
            })}
            className="employee-reject-button"
          >
            Automaattitäyttö pois päältä
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderAllowanceAutofillReminderDialog() {
    const {
      item,
      toggleTripDialog,
      onGoingTrip,
      endedTrips,
    } = this.props;
    const { allowanceAutofillReminderDialogOpen, editingAllowanceValue } = this.state;

    const overlappingTrips = getOverlappingTrips(item, onGoingTrip, endedTrips);

    return (
      <Dialog
        className="mui-dialog-background"
        fullScreen={false}
        open={allowanceAutofillReminderDialogOpen}
        onClose={() => this.setState({
          allowanceAutofillReminderDialogOpen: false,
          editingAllowanceValue: null,
        })}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="responsive-dialog-title-button-only">
          <IconButton
            aria-label="close"
            onClick={() => this.setState({
              allowanceAutofillReminderDialogOpen: false,
              editingAllowanceValue: null,
            })}
          >
            <Icon
              icon="close"
              role="button"
            />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Täytä työmatkatiedot jotta päivärahat täytetään automaattisesti.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Button
            onClick={() => this.setState({
              allowanceAutofillReminderDialogOpen: false,
              editingAllowanceValue: null,
            }, () => toggleTripDialog(null, item.date, overlappingTrips, endedTrips))}
            // this.openOrCloseTrip(null, tripDialogParams.defaultTripDate, tripDialogParams.overlappingTrips, tripDialogParams.endedTrips, clonableTrip))
            className="employee-accept-button"
          >
            Täytä matka
          </Button>

          <Button
            onClick={() => this.setState({
              allowanceAutofillReminderDialogOpen: false,
              editingAllowanceValue: null,
            }, () => this.updateTimelogAllowance(editingAllowanceValue, false))}
            className="employee-reject-button"
          >
            Myöhemmin
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderWorkTaskEntryProducts(workTaskEntry) {
    const { item, uiStore: { currentUser } } = this.props;
    const foundUserProductCollections = currentUser.userProductCollections.filter((userProductCollection) => userProductCollection.workOrderId === item.workOrderId && userProductCollection.date.toISOString() === item.date.toISOString());
    // User product collection where either:
    // 1. workTaskEntryId matches the clicked workTaskEntry (opening specific work task entry's user product collection)
    // 2. workTaskEntryId and clicked workTaskEntry's id are both falsy (opening the day's generic user product collection that isn't under any work task entry)
    const userProductCollection = foundUserProductCollections.find((collection) => collection.workTaskEntryId === workTaskEntry?.id || (!collection.workTaskEntryId && !workTaskEntry?.id));

    const userProducts = userProductCollection?.userProducts;
    let userProductText = 'Kirjaa';

    if (userProducts && userProducts.length > 1) {
      userProductText = `${userProducts.length} nimikettä`;
    } else if (userProducts && userProducts.length === 1) {
      userProductText = '1 nimike';
    }

    return (
      <Grid
        container
        key={`user-products-${item.date.format('DD-MM-YYYY')}-${workTaskEntry?.id || 'daily'}`}
        style={{ textAlign: 'left', hyphens: 'auto' }}
      >
        <Grid
          item
          xs={5}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            minHeight: '50px',
          }}
        >
          {workTaskEntry ? (
            <>
              <div>{truncate(workTaskEntry.workTask.identifierOne, 12)}</div>
              <div>{`${workTaskEntry.from || '?'} - ${workTaskEntry.to || '?'}`}</div>
            </>
          ) : (
            <div>
              Yleiset
            </div>
          )}
        </Grid>
        <Grid
          style={{
            color: 'var(--mdc-theme-primary)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          item
          xs={5}
          onClick={() => { this.openProductDialog(userProductCollection, workTaskEntry); }}
        >
          {userProductText}
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={() => { this.openProductDialog(userProductCollection, workTaskEntry); }}
        >
          <IconButton
            style={{
              color: 'var(--mdc-theme-primary)',
              // backgroundColor: 'rgb(128 128 128 / 75%)',
              borderRadius: '30px',
              padding: 0,
              // height: 'fit-content',
            }}
          >
            <Add />
          </IconButton>
        </Grid>
      </Grid>
    );
  }

  // NOTE: Gutted version of renderWorkTaskEntryProducts(), could probably be refactored
  renderProducts() {
    const { item, uiStore: { currentUser } } = this.props;

    const foundUserProductCollection = currentUser.userProductCollections.find((oldUserProductCollection) => oldUserProductCollection.workOrderId === item.workOrderId && oldUserProductCollection.date.toISOString() === item.date.toISOString());

    const userProductCollection = foundUserProductCollection || new UserProductCollection({
      workOrderId: item.workOrderId,
      date: item.date,
      userProducts: [],
      files: [],
    });

    const userProducts = userProductCollection?.userProducts;
    let userProductText = 'Kirjaa';

    if (userProducts && userProducts.length > 1) {
      userProductText = `${userProducts.length} nimikettä`;
    } else if (userProducts && userProducts.length === 1) {
      userProductText = '1 nimike';
    }

    return (
      // style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 10px' }}
      <div
        style={{
          textAlign: 'center',
          width: '100%',
          margin: 0,
          padding: '0 10px 0 40px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          fontSize: '14px',
          height: '48px',
        }}
      >
        <Grid
          container
          key={`user-products-${item.date.format('DD-MM-YYYY')}`}
          style={{ textAlign: 'left', hyphens: 'auto' }}
        >
          <Grid
            style={{
              color: 'var(--mdc-theme-primary)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => { this.openProductDialog(userProductCollection); }}
            item
            xs={10}
          >
            {/* {entryUserProductCollection?.userProducts?.length ? `${entryUserProductCollection.userProducts.length} nimikettä` : 'Lisää'} */}
            {userProductText}
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => { this.openProductDialog(userProductCollection); }}
          >
            <IconButton
              style={{
                color: 'var(--mdc-theme-primary)',
                // backgroundColor: 'rgb(128 128 128 / 75%)',
                borderRadius: '30px',
                padding: 0,
                // height: 'fit-content',
              }}
            >
              <Add />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    );
  }

  // Optimization: no need to render on every single row? Move to TimelogView, at least?
  renderProductDialog() {
    const { item, workOrder, employerUpdateMode } = this.props;
    const { productDialogOpen, openUserProductCollection, clickedWorkTaskEntry } = this.state;

    return (
      <ProductDialog
        open={productDialogOpen}
        toggleDialog={this.closeProductDialog}
        date={item.date}
        workOrderId={item.workOrderId}
        userProductCollection={openUserProductCollection}
        workOrderName={workOrder.name}
        productChanged={false}
        workTaskEntry={clickedWorkTaskEntry}
        hourlyWorkorder={workOrder.workHourType === 'hourly'}
        employerUpdateMode={employerUpdateMode}
      />
    );
  }

  render() {
    const {
      activeTab,
      item,
      employerAcceptance,
      uiStore: { currentUser: { accountInfo: { workOrderTripsEnabled }, accountId } },
      workOrder,
      employerUpdateMode,
      highlight,
    } = this.props;
    const dayStr = item.date.format('D.M. dd');
    const versionsDifferent = this.versionsDifferent();
    const isDraft = item.isDraft();

    return (
      <div
        className={`${((activeTab === 1 && (accountId === 43 || accountId === 1 || accountId === 58)) || activeTab === 3) ? 'mobile-fit-content' : ''}`}
        style={{ borderTop: '1px gray solid', borderBottom: highlight && '1px solid var(--mdc-theme-primary)', fontSize: '16px' }}
      >

        {/* The employer comment, which may be added by the employer upon rejecting the work hour in employer acceptance mode */}
        {item.employerComment && (item.status === 'rejected' || item.status === 'draft') && (
          <div className="employer-comment">
            <Icon icon="info" />
            <div className="employer-comment-inner">
              {item.employerComment}
            </div>
          </div>
        )}

        {item.taxExemptTripExpenses && (
          item.taxExemptTripExpenses.map((expense) => {
            if (expense.status === 'rejected' && expense.employerComment) {
              return (
                <div key={`${item.id}-expense-employer-comment`}>
                  <div className="employer-comment">
                    <Icon icon="info" />
                    <div className="employer-comment-inner">
                      {expense.employerComment}
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })
        )}

        {item.tripRoutes && (
          item.tripRoutes.map((tripRoute) => {
            if (tripRoute.status === 'rejected' && tripRoute.employerComment) {
              return (
                <div key={`${item.id}-trip-route-employer-comment`}>
                  <div className="employer-comment">
                    <Icon icon="info" />
                    <div className="employer-comment-inner">
                      {tripRoute.employerComment}
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })
        )}

        {item.workOrderTripEmployerComment && (
          <div className="employer-comment">
            <Icon icon="info" />
            <div className="employer-comment-inner">
              {item.workOrderTripEmployerComment}
            </div>
          </div>
        )}

        {!employerUpdateMode && versionsDifferent && item.employerUpdateComment?.comment && (
          <div className="employer-comment">
            <Icon icon="info" />
            <div className="employer-comment-inner">
              {item.employerUpdateComment?.comment}
            </div>
          </div>
        )}

        <div style={{ width: '100%', display: 'flex' }}>
          <div
            className={`sticky-left ${((activeTab === 1 && (accountId === 43 || accountId === 1 || accountId === 58)) || activeTab === 3) ? 'mobile-column' : ''}`}
            style={{
              color: item.status === 'accepted' && !isDraft ? buttonColors.green.color : 'var(--mdc-theme-primary)',
              padding: 0,
              textAlign: 'center',
              width: '17.5%',
              textTransform: 'uppercase',
              fontSize: '.875rem', // Copied from the old DataTable implementation
              display: 'flex',
              // flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <span className={`${((activeTab === 0 || activeTab === 5) && item.workTaskEntries && item.workTaskEntries.length > 0) || versionsDifferent || activeTab === 4 ? 'vertical-align-top' : 'vertical-align-middle'}`} style={{ width: '65px' }}>{dayStr}</span>
            {/* style={{ position: 'relative' }} */}
            <div className="timelog-row-icon vertical-align-middle" style={{ position: 'relative' }}>
              {this.props.disabled && (
                <Icon icon="lock_outline" />
              )}
              {!isDraft && item.status === 'pending' && !this.props.disabled && (
                <Icon icon="done" />
              )}
              {!isDraft && item.status === 'accepted' && !this.props.disabled && employerAcceptance && (
                <Icon icon="done_all" />
              )}
              {!isDraft && item.status === 'rejected' && !this.props.disabled && employerAcceptance && !item.employerComment && (
                <Icon icon="info" />
              )}
              {!isDraft && item.status === 'accepted' && !this.props.disabled && !employerAcceptance && (
                <Icon icon="done" />
              )}
            </div>
          </div>
          {activeTab === 0 && (workOrder.workHourType === 'hourly' ? this.getWorkTaskEntries() : this.getWorktimeCells())}
          {activeTab === 1 && this.getTravelCells()}
          {activeTab === 2 && this.getAllowances()}
          {activeTab === 3 && this.getExtrasCells()}
          {activeTab === 4 && this.getDescriptionCells()}
          {activeTab === 5 && this.getProducts()}
        </div>

        <div style={{ width: '100%' }}>
          {employerUpdateMode && Object.keys(item.latestEmployeeVersion).length !== 0 && (item.employerUpdateComment?.comment || versionsDifferent) && (
            <TextField {...{
              className: 'no-resize employer-update-comment-input',
              label: 'Muutosten perustelut:',
              // disabled,
              // onClick: handleKeyDown,
              // onKeyDown: handleKeyDown,
              // onBlur: (event) => {
              //   this.doOnBlur(attr, event.target.value, event);
              // },
              onChange: (event) => {
                // this.doOnChange(attr, event.target.value, event);
                item.employerUpdateComment.comment = event.target.value;
                // handleKeyDown(event);
              },
              rootProps: { ripple: false },
              style: {
                margin: 0,
                padding: 0,
                width: '100%',
              },
              // textarea: true,
              theme: ['textWhite', 'secondaryBg'],
              // value: (this.state != null && this.state[attr] != null && !Number.isNaN(this.state[attr])) ? this.state[attr] : '',
              value: item.employerUpdateComment.comment || '',
            }}
            />
          )}
        </div>
        {/* Optimize: these dialogs could be once per TimelogView, not every TimelogRow */}
        {activeTab === 2 && workOrderTripsEnabled && this.renderDisableAllowanceAutofillDialog()}
        {activeTab === 2 && workOrderTripsEnabled && this.renderAllowanceAutofillReminderDialog()}
        {activeTab === 5 && this.renderProductDialog()}
      </div>
    );
  }
}

export default TimelogRow;
