/* eslint-disable */
import React, { Component } from 'react';
import { Document, Page } from 'react-pdf';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import { Grid, GridInner, GridCell } from '@rmwc/grid';
import { Button } from '@rmwc/button';
import { Icon } from '@rmwc/icon';
import MUIDataTable from 'mui-datatables';
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   TextField,
// } from '@material-ui/core';
import { isEqual } from 'lodash';
import LoadingSpinner from '../shared/LoadingSpinner';
import FilePreviewDialog from '../shared/FilePreviewDialog';
import EmployeeDialog from './EmployeeDialog';
import Validity from '../models/Validity';
import moment from '../utils/moment';
import normalizeFileName from '../utils/NormalizeFileName';
import './EmployeesTable.css';
import { onDownload, onViewColumnsChange } from '../utils/datatableUtils';

import { Tooltip, IconButton } from '@material-ui/core';
import FlipToFrontIcon from '@material-ui/icons/FlipToFront';

const newBadge = () => {
  return (
    <div
      style={{ padding: '5px 10px', width: 'fit-content', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
    >
      Uusi
    </div>
  );
}

const tableSelectionButton = (t, preset, activePreset, onClick) => {
  return (
    <Button
      className={activePreset === preset ? 'selected-table-selection-button' : 'table-selection-button'}
      onClick={() => onClick(preset)}
      type="button"
      key={`${preset}-button`}
    >
      {t(`employer_table.columnPresets.${preset}`)}
    </Button>
  )
}

@inject('t', 'uiStore', 'employerUserStore')
@observer
class EmployeesTable extends Component {
  constructor(props) {
    super(props);

    // const { employerUserStore } = this.props;

    this.state = {
      columns: [],
      activePreset: 'base',
      showImageDialog: false,
      imageUrl: '',
      imageName: '',
      employeeEditDialogOpen: false,
      selectedEmployee: null,
      readOnlyMode: false,
    };

    // employerUserStore.getUsers();
  }

  renderCustomImageColumn = (imageUrl, imageName) => {
    // Check if imageUrl exists and is a string
    if (typeof imageUrl === 'string') {
      // Remove the query parameter before passing the URL to Document
      const pdfUrl = imageUrl.split('?')[0];

      if (pdfUrl && pdfUrl.endsWith('.pdf')) {
        return (
          <div style={{ width: '100px', cursor: 'pointer' }}>
            <Document
              file={pdfUrl}
              loading="Avataan PDF..."
              noData="Tiedostoa ei löytynyt"
              error="Tiedoston avaus epäonnistui"
            >
              <Page
                pageNumber={1}
                width={100}
                height={80}
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({ imageUrl, imageName, showImageDialog: true })
                }}
              />
            </Document>
          </div>
        );
      } else if (imageUrl) {
        return (
          <div style={{ width: '100px' }}>
            <img
              src={imageUrl}
              onClick={(e) => {
                e.stopPropagation();
                this.setState({ imageUrl, imageName, showImageDialog: true })
              }}
              style={{ width: '100px', height: '80px', objectFit: 'cover', cursor: 'pointer' }}
              alt="Image"
            />
          </div>
        );
      }
    }
    // Handle cases where the URL is empty or not a string
    return null;
  };

  cleanFileName(fileType, user) {
    const fileTypeClean = fileType.toLowerCase();
    const lastNameClean = user.lastName ? user.lastName.trim().toLowerCase() : null;
    const firstNameClean = user.firstName ? user.firstName?.trim().toLowerCase() : null;
    return (lastNameClean && firstNameClean) ? `${fileTypeClean}_${lastNameClean}_${firstNameClean}` : fileTypeClean;
  }

  setColumnDefinitions(data, firstRender = false) {
    const { t, employerUserStore } = this.props;
    // const onlyTruthyFilterOptions = {
    //   // names: [],
    //   logic: (value) => value !== null && value !== undefined && value !== '',
    // };
    const handleEmptyFilters = {
      renderValue: v => {
        if (v === '' || v === null || v === undefined) {
          return 'Tyhjä'
        }
        return v;
      }
    }

    const { defaultFilterLists = {}, uiStore: { currentUser: { accountId, accountInfo: { salaryPeriodCategoriesEnabled } } } } = this.props;
    // NOTE: Last column commented out is the workOrders column, use it when users actually returns the workorder names.
    // WorkOrders column needs to exist in all column sets!! There may be a better way to do it but figure it out yourself
    const columnDefinitions = [
      {
        name: 'lastName',
        label: 'Sukunimi',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return value ? value : newBadge;
          },
        },
      },
      {
        name: 'firstName',
        label: 'Etunimi',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return value ? value : newBadge;
          },
        },
      },
      {
        name: 'socialSecurityNumber',
        label: 'Henkilötunnus',
        options: {
          filter: false,
          searchable: false,
          sort: true,
          print: false,
          download: false,
          customBodyRenderLite: (dataIndex) => {
            const ssn = data[dataIndex].socialSecurityNumber;
            return (
              // <div style={{ filter: 'blur(4px)'}}>
              ssn
              // </div>
            );
          },
        },
      },
      {
        name: 'street',
        label: 'Katuosoite',
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: 'zipCode',
        label: 'Postinumero',
        options: {
          filter: true,
          sort: true,
          filterType: 'multiselect',
          filterOptions: handleEmptyFilters,
          filterList: employerUserStore.employeesFilters.zipCode,
          customFilterListOptions: {
            render: v => {
              if (v === null || v === '' || v === undefined) {
                return `Postinumero: Tyhjä`
              } else {
                return `Postinumero: ${v}`
              }
            }
          },
        },
      },
      {
        name: 'city',
        label: 'Paikkakunta',
        options: {
          filter: true,
          sort: true,
          filterType: 'multiselect',
          filterOptions: handleEmptyFilters,
          filterList: employerUserStore.employeesFilters.city,
          customFilterListOptions: {
            render: v => {
              if (v === null || v === '' || v === undefined) {
                return `Paikkakunta: Tyhjä`
              } else {
                return `Paikkakunta: ${v}`
              }
            }
          },
        },
      },
      {
        name: 'email',
        label: 'Sähköposti',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value) => (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{value}</span>
              <Tooltip title="Kopioi leikepöydälle">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(value);
                  }}
                  size="small"
                  style={{ marginLeft: 8, transform: 'rotate(90deg)', padding: "6px" }}
                >
                  <FlipToFrontIcon fontSize="small" style={{fontSize: '1rem', color: '#D3D3D3'}} />
                </IconButton>
              </Tooltip>
            </div>
          ),
        },
      },
      {
        name: 'phoneNumber',
        label: 'Puhelinnumero',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value) => {
            return (
              <a
                className='employee-phone'
                href={`tel:${value}`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {value}
              </a>
            )
          }
        },
      },
      {
        name: 'iceName',
        label: 'Lähiomaisen nimi',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'iceNumber',
        label: 'Lähiomaisen puhelinnumero',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'currentAccount.active',
        label: 'Aktiivinen',
        options: {
          sort: true,
          print: false,
          searchable: false,
          filterType: 'multiselect',
          customFilterListOptions: { render: v => `Aktiivinen: ${v}` },
          filterList: employerUserStore.employeesFilters['currentAccount.active']?.length !== 0 ? defaultFilterLists['active'] : employerUserStore.employeesFilters['currentAccount.active'],
          customBodyRender: (value) => {
            const result = value ? 'Kyllä' : 'Ei';
            return (
              result
            );
          },
        },
      },
      {
        // hasCompletedRegistration is a computed field in the User model
        name: 'hasCompletedRegistration',
        label: 'Rekisteröitynyt',
        options: {
          sort: true,
          print: false,
          searchable: false,
          filterType: 'multiselect',
          customFilterListOptions: { render: v => `Rekisteröitynyt: ${v}` },
          filterList: employerUserStore.employeesFilters['hasCompletedRegistration']?.length !== 0 ? defaultFilterLists['hasCompletedRegistration'] : employerUserStore.employeesFilters['hasCompletedRegistration'],
          customBodyRender: (value) => {
            const result = value ? 'Kyllä' : 'Ei';
            return (
              result
            );
          },
        },
      },
      // {
      //   name: 'workOrders',
      //   label: 'Keikat',
      //   options: {
      //     display: 'excluded',
      //     filter: true,
      //     filterType: 'dropdown',
      //     filterList: filterList[0].length ? filterList[0] : null,
      //     sort: false,
      //     searchable: true
      //   }
      // },
      {
        name: 'personalPictureUrl',
        label: 'Kuva',
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRenderLite: (dataIndex) => {
            const imageUrl = data[dataIndex].personalPictureUrl;
            const imageName = this.cleanFileName('profiilikuva', data[dataIndex]);
            // Remove the query parameter before passing the URL to Document
            const pdfUrl = imageUrl.split('?')[0];
            if (pdfUrl.endsWith('.pdf')) {
              return (
                <div style={{
                  width: '100px',
                  // height: '70px',
                  cursor: 'pointer'
                }}>
                  <Document
                    file={pdfUrl}
                    loading="Avataan PDF..."
                    noData="Tiedostoa ei löytynyt"
                    error="Tiedoston avaus epäonnistui"
                  >
                    <Page
                      pageNumber={1}
                      width={70}
                      height={70}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.setState({ imageUrl, imageName, showImageDialog: true })
                      }}
                    />
                  </Document>
                </div>
              );
            } else if (imageUrl) {
              return (
                <div style={{ width: '70px', height: '70px' }}>
                  <img
                    src={imageUrl}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.setState({
                        imageUrl,
                        imageName,
                        showImageDialog: true,
                      })
                    }}
                    style={{
                      width: '70px',
                      height: '70px',
                      objectFit: 'cover',
                      borderRadius: '50%',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              );
            }
          },
        },
      },
      {
        name: 'taxNumber',
        label: 'Veronumero',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'birthDate',
        label: 'Syntymäaika',
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: 'taxIncomeCardUrl',
        label: 'Verokortti',
        options: {
          filter: false,
          sort: true,
          download: false,
          customBodyRenderLite: (dataIndex) => {
            // Note: This uses another function than the other columns
            const user = data[dataIndex];
            return this.renderCustomImageColumn(data[dataIndex].taxIncomeCardUrl, this.cleanFileName('verokortti', user));
          },
        },
      },
      {
        name: 'taxPercent',
        label: 'Veroprosentti',
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: 'bankNumber',
        label: 'Tilinumero',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'currentAccount.extid',
        label: 'Ulkoinen tunniste',
        options: {
          filter: true,
          filterType: 'multiselect',
          customFilterListOptions: {
            render: v => {
              if (v === null || v === '' || v === undefined) {
                return `Ulkoinen tunniste: Tyhjä`
              } else {
                return `Ulkoinen tunniste: ${v}`
              }
            }
          },
          // Turn into a reusable object (e.g. "allOrNothingFilterOptions")
          filterOptions: {
            names: ['Ei asetettu'],
            logic: (value, filters) => {
              if (filters.length === 0) {
                return true;
              }
              if (filters.includes('Ei asetettu')) {
                // If value is falsy, we show the row
                return value;
              }
              return value !== filters[0];
            },
          },
          sort: true,
          filterList: employerUserStore.employeesFilters['currentAccount.extid'],
        },
      },
      {
        name: 'currentAccount.extgroup',
        label: 'Ulkoinen ryhmätunniste',
        options: {
          filter: true,
          customFilterListOptions: {
            render: v => {
              if (v === null || v === '' || v === undefined) {
                return `Ulkoinen ryhmätunniste: Tyhjä`
              } else {
                return `Ulkoinen ryhmätunniste: ${v}`
              }
            }
          },
          // Turn into a reusable object (e.g. "specificValueOrNothingFilterOptions")
          filterOptions: {
            names: ["Ei asetettu", ...new Set(data?.map((row) => {
              // TODO: This set has "undefined" as its final element, for whatever reason. Needs to be removed
              if (row.currentAccount.extgroup) {
                return row.currentAccount.extgroup;
              }
            }))],
            logic: (value, filters) => {
              if (filters.length === 0) {
                return true;
              }
              if (filters.includes('Ei asetettu')) {
                // If value is falsy, we show the row
                return value;
              }
              return value !== filters[0];
            },
          },
          filterOptions: handleEmptyFilters,
          filterList: employerUserStore.employeesFilters['currentAccount.extgroup'],
          sort: true,
          filterType: 'multiselect',
        },
      },
      ...(salaryPeriodCategoriesEnabled ? [{
        name: 'salaryPeriodCategory.name',
        label: t('profile.labels.salaryPeriodCategory'),
        options: {
          filter: true,
          customFilterListOptions: { render: v => `${t('profile.labels.salaryPeriodCategory')}: ${v}` },
          sort: true,
          // filterType: 'multiselect',
        },
      }] : []),
      {
        name: 'currentAccount.subcontractor',
        label: 'Alihankkija',
        options: {
          filter: true,
          customFilterListOptions: { render: v => `Alihankkija: ${v}` },
          sort: true,
          filterType: 'multiselect',
          customBodyRender: (value) => {
            const result = value ? 'Kyllä' : 'Ei';
            return (
              result
            );
          },
          filterList: employerUserStore.employeesFilters['currentAccount.subcontractor'],
        },
      },
      {
        name: 'details.cards.occupationalSafetyCard.number',
        label: 'Työturvallisuus-kortin nro',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'details.cards.occupationalSafetyCard.validThrough',
        label: 'Työturvallisuuskortin voimassaolo',
        options: {
          filter: false,
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            return this.renderCardValidity(data[dataIndex].details?.cards?.occupationalSafetyCard.validThrough);
          },
        },
      },
      {
        name: 'details.cards.occupationalSafetyCard.imageURL',
        label: 'Työturvallisuuskortti',
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRenderLite: (dataIndex) => {
            return this.renderCardImage(data[dataIndex].details?.cards?.occupationalSafetyCard, this.cleanFileName('työturvallisuuskortti', data[dataIndex]));
          },
        },
      },
      {
        name: 'details.cards.hotWorkLicense.number',
        label: 'Tulityökortin numero',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'details.cards.hotWorkLicense.validThrough',
        label: 'Tulityökortin voimassaolo',
        options: {
          filter: false,
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            return this.renderCardValidity(data[dataIndex].details?.cards?.hotWorkLicense?.validThrough);
          },
        },
      },
      {
        name: 'details.cards.hotWorkLicense.imageURL',
        label: 'Tulityökortti',
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRenderLite: (dataIndex) => {
            return this.renderCardImage(data[dataIndex].details?.cards?.hotWorkLicense, this.cleanFileName('tulityökortti', data[dataIndex]));
          },
        },
      },
      {
        name: 'details.cards.firstAidTraining.class',
        label: 'Ensiapukurssi',
        options: {
          filter: true,
          sort: true,
          filterList: employerUserStore.employeesFilters['details.cards.firstAidTraining.class'],
          filterType: 'multiselect',
          filterOptions: handleEmptyFilters,
          customFilterListOptions: {
            render: v => {
              if (v === null || v === '' || v === undefined) {
                return `Ensiapukurssi: Tyhjä`
              } else {
                return `Ensiapukurssi: ${v}`
              }
            }
          },
        },
      },
      {
        name: 'details.cards.firstAidTraining.validThrough',
        label: 'Ensiapukoulutuksen voimassaolo',
        options: {
          filter: false,
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            return this.renderCardValidity(data[dataIndex].details?.cards?.firstAidTraining?.validThrough);
          },
        },
      },
      {
        name: 'details.cards.firstAidTraining.imageURL',
        label: 'Ensiapukortti',
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRenderLite: (dataIndex) => {
            return this.renderCardImage(data[dataIndex].details?.cards?.firstAidTraining, this.cleanFileName('ensiapukortti', data[dataIndex]));
          },
        },
      },
      {
        name: 'details.cards.valttiCard.number',
        label: 'Valttikortin numero',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'details.cards.valttiCard.validThrough',
        label: 'Valttikortin voimassaolo',
        options: {
          filter: false,
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            return this.renderCardValidity(data[dataIndex].details?.cards?.valttiCard?.validThrough);
          },
        },
      },
      {
        name: 'details.cards.valttiCard.imageURL',
        label: 'Valttikortti',
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRenderLite: (dataIndex) => {
            return this.renderCardImage(data[dataIndex].details?.cards?.valttiCard, this.cleanFileName('valttikortti', data[dataIndex]));
          },
        },
      },
      // TODO: Hardcoded for accounts 1, 3 and 10 (3 and 10 are test accounts)
      // ...(accountId === 1 || accountId === 3 || accountId === 10 ? [{
      //   name: 'details.cards.machineryPermit.number',
      //   label: 'Koneenkäyttöluvan numero',
      //   options: {
      //     filter: false,
      //     sort: false,
      //   },
      // }] : []),
      // ...(accountId === 1 || accountId === 3 || accountId === 10 ? [{
      //   name: 'details.cards.machineryPermit.validThrough',
      //   label: 'Koneenkäyttöluvan voimassaolo',
      //   options: {
      //     filter: false,
      //     sort: false,
      //     download: false,
      //     customBodyRenderLite: (dataIndex) => {
      //       return this.renderCardValidity(data[dataIndex].details.cards.machineryPermit.validThrough);
      //     },
      //   },
      // }] : []),
      ...(accountId === 1 || accountId === 3 || accountId === 10 ? [{
        name: 'details.cards.machineryPermit.imageURL',
        label: 'Koneenkäyttölupa',
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRenderLite: (dataIndex) => {
            return this.renderCardImage(data[dataIndex].details?.cards?.machineryPermit, this.cleanFileName('koneenkäyttölupa', data[dataIndex]));
          },
        },
      }] : []),

      // TODO: Hardcoded for accounts 7, 3 and 10 (3 and 10 are test accounts)
      ...(accountId === 7 || accountId === 3 || accountId === 10 ? [{
        name: 'details.cards.roadSafetyCard.number',
        label: 'Tieturvakortin numero',
        options: {
          filter: false,
          sort: false,
        },
      }] : []),
      ...(accountId === 7 || accountId === 3 || accountId === 10 ? [{
        name: 'details.cards.roadSafetyCard.validThrough',
        label: 'Tieturvakortin voimassaolo',
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRenderLite: (dataIndex) => {
            return this.renderCardValidity(data[dataIndex].details?.cards?.roadSafetyCard?.validThrough);
          },
        },
      }] : []),
      ...(accountId === 7 || accountId === 3 || accountId === 10 ? [{
        name: 'details.cards.roadSafetyCard.imageURL',
        label: 'Tieturvakortti',
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRenderLite: (dataIndex) => {
            return this.renderCardImage(data[dataIndex].details?.cards?.roadSafetyCard, this.cleanFileName('tieturvakortti', data[dataIndex]));
          },
        },
      }] : []),

      // TODO: Hardcoded for accounts 1, 7, 42, 159, 3 and 10 (3 and 10 are test accounts)
      ...(accountId === 1 || accountId === 7 || accountId === 3 || accountId === 10 || accountId === 42 || accountId === 49 || accountId === 58 || accountId === 159 ? [{
        name: 'details.cards.ssgCard.number',
        label: 'SSG-kortin numero',
        options: {
          filter: false,
          sort: false,
        },
      }] : []),
      ...(accountId === 1 || accountId === 7 || accountId === 3 || accountId === 10 || accountId === 42 || accountId === 49 || accountId === 58 || accountId === 159  ? [{
        name: 'details.cards.ssgCard.validThrough',
        label: 'SSG-kortin voimassaolo',
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRenderLite: (dataIndex) => {
            return this.renderCardValidity(data[dataIndex].details?.cards?.ssgCard?.validThrough);
          },
        },
      }] : []),
      ...(accountId === 1 || accountId === 7 || accountId === 3 || accountId === 10 || accountId === 42 || accountId === 49 || accountId === 58 || accountId === 159  ? [{
        name: 'details.cards.ssgCard.imageURL',
        label: 'SSG-kortti',
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRenderLite: (dataIndex) => {
            return this.renderCardImage(data[dataIndex].details?.cards?.ssgCard, this.cleanFileName('ssg', data[dataIndex]));
          },
        },
      }] : []),

      // TODO: Hardcoded for accounts 7, 3 and 10 (3 and 10 are test accounts)
      ...(accountId === 7 || accountId === 3 || accountId === 10 || accountId === 42 ? [{
        name: 'details.cards.id06Card.number',
        label: 'ID06-kortin numero',
        options: {
          filter: false,
          sort: false,
        },
      }] : []),

      ...(accountId === 7 || accountId === 3 || accountId === 10 || accountId === 42 ? [{
        name: 'details.cards.id06Card.validThrough',
        label: 'ID06-kortin voimassaolo',
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRenderLite: (dataIndex) => {
            return this.renderCardValidity(data[dataIndex].details?.cards?.id06Card?.validThrough);
          },
        },
      }] : []),

      ...(accountId === 7 || accountId === 3 || accountId === 10 || accountId === 42 ? [{
        name: 'details.cards.id06Card.imageURL',
        label: 'ID06-kortti',
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRenderLite: (dataIndex) => {
            return this.renderCardImage(data[dataIndex].details?.cards?.id06Card, this.cleanFileName('id06', data[dataIndex]));
          },
        },
      }] : []),
      // WIP for searching users by which work orders they're participating in. Should be an array of the user's recent work orders
      // {
      //   name: 'workOrders',
      //   label: 'Keikat',
      //   options: {
      //     display: 'excluded',
      //     filter: true,
      //     filterType: 'dropdown',
      //     sort: false,
      //     searchable: true
      //   }
      // },
      {
        name: 'details.clothing.size',
        label: 'Vaatekoko kirjaimin',
        options: {
          searchable: false,
          sort: true,
          filterList: employerUserStore.employeesFilters['details.clothing.size'],
          filterType: 'multiselect',
          filterOptions: handleEmptyFilters,
          customFilterListOptions: {
            render: v => {
              if (v === null || v === '' || v === undefined) {
                return `Vaatekoko kirjaimin: Tyhjä`
              } else {
                return `Vaatekoko kirjaimin: ${v}`
              }
            }
          },
        },
      },
      {
        name: 'details.clothing.number',
        label: 'Vaatekoko numeroin',
        options: {
          searchable: false,
          sort: true,
          filterList: employerUserStore.employeesFilters['details.clothing.number'],
          filterType: 'multiselect',
          filterOptions: handleEmptyFilters,
          customFilterListOptions: {
            render: v => {
              if (v === null || v === '' || v === undefined) {
                return `Vaatekoko numeroin: Tyhjä`
              } else {
                return `Vaatekoko numeroin: ${v}`
              }
            }
          },
        },
      },
      {
        name: 'details.clothing.shoeSize',
        label: 'Kengän numero',
        options: {
          searchable: false,
          sort: true,
          filterList: employerUserStore.employeesFilters['details.clothing.shoeSize'],
          filterType: 'multiselect',
          filterOptions: handleEmptyFilters,
          customFilterListOptions: {
            render: v => {
              if (v === null || v === '' || v === undefined) {
                return `Kengän numero: Tyhjä`
              } else {
                return `Kengän numero: ${v}`
              }
            }
          },
        },
      },
      {
        name: 'details.clothing.hasHelmet',
        label: 'On suojakypärä',
        options: {
          searchable: false,
          sort: true,
          filterType: 'multiselect',
          customBodyRender: (value) => {
            const result = value ? 'Kyllä' : 'Ei';
            return (
              result
            );
          },
          customFilterListOptions: { render: v => `On suojakypärä: ${v}` },
          filterList: employerUserStore.employeesFilters['details.clothing.hasHelmet'],
        },
      },
      {
        name: 'details.clothing.helmetSize',
        label: 'Suojakypärän koko',
        options: {
          searchable: false,
          sort: true,
          filterList: employerUserStore.employeesFilters['details.clothing.helmetSize'],
          filterType: 'multiselect',
          filterOptions: handleEmptyFilters,
          customFilterListOptions: {
            render: v => {
              if (v === null || v === '' || v === undefined) {
                return `Suojakypärän koko: Tyhjä`
              } else {
                return `Suojakypärän koko: ${v}`
              }
            }
          },
        },
      },
      {
        name: 'helmetImageUrl',
        label: 'Kuva kypärästä',
        options: {
          filter: false,
          searchable: false,
          sort: true,
          download: false,
          customBodyRenderLite: (dataIndex) => {
            // Note: This uses another function than the other columns
            const user = data[dataIndex];
            return this.renderCustomImageColumn(data[dataIndex].helmetImageUrl, this.cleanFileName('kypärä', user));
          },
        },
      },
      {
        name: 'edit',
        label: 'Muokkaa',
        options: {
          sort: false,
          filter: false,
          empty: true,
          print: false,
          customBodyRenderLite: (dataIndex) => {
            return (
              <Icon
                icon="edit"
                style={{
                  cursor: 'pointer',
                  padding: '0 10px'
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  this.openEmployeeEditDialog(data[dataIndex]);
                }}
              />
            )
          }
        },
      },
    ];

    console.log(employerUserStore.employeesFilters);

    return columnDefinitions;
  }

  componentDidMount() {
    const { data, presets } = this.props;

    const columnDefinitions = this.setColumnDefinitions(data, true);

    this.setState({
      data: data,
      columns: columnDefinitions,
    }, () => {
      // Setting the first preset as the active one by default
      const defaultPreset = Object.keys(presets)[0];
      this.toggleColumnVisibilityPreset(defaultPreset);
      return true;
    });
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    const { data: prevData } = prevProps;

    if (data && !isEqual(data, prevData)) {
      const columnDefinitions = this.setColumnDefinitions(data);

      this.setState({
        data: data,
        columns: columnDefinitions,
      })
    }
  }

  openEmployeeEditDialog(employee) {
    this.setState({
      employeeEditDialogOpen: true,
      selectedEmployee: employee,
    });
  }

  closeEmployeeEditDialog = () => {
    this.setState({
      employeeEditDialogOpen: false,
      selectedEmployee: null,
      readOnlyMode: false,
    });
  }

  // This is copied from Validity since some changes were needed for rendering
  renderCardValidity(validThrough) {
    if (!validThrough) {
      return null;
    }

    const currentDateMoment = moment().local();
    const validThroughMoment = Validity.convertDate(validThrough).dateObject; // moment(secondDate, 'MM/YYYY');
    // Add 1 to the months because for some reason they're zero indexed (e.g. January is 0 and December is 11)
    // const currentMonth = currentDate.month() + 1;
    // const secondDateMonth = secondDate.month() + 1;

    // Object not returned (invalid date given to conversion), which means the date cannot be calculated
    if (!validThroughMoment) {
      return null;
    }

    if (currentDateMoment > validThroughMoment.endOf('month')) {
      // Expired (last day of the month)
      return (
        <div style={{ color: 'red', fontWeight: 'bold' }}>
          {validThrough}
        </div>
      );
    } else if (currentDateMoment.add(1, 'M') > validThroughMoment.endOf('month')) {
      // Expiring within a month
      return (
        <div style={{ color: 'var(--mdc-theme-primary)', fontWeight: 'bold' }}>
          {validThrough}
        </div>
      );
    } else {
      return (
        <div style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
          {validThrough}
        </div>
      );
    }
  }

  renderCardImage(cardType, imageName) {
    if (cardType && cardType.imageURL) {
      // console.log(cardType.imageURL);
      if (cardType.imageURL.endsWith('.pdf')) {
        return (
          <div style={{ width: '100px', cursor: 'pointer' }}
            onClick={(e) => {
              e.preventDefault();
              this.setState({
                imageUrl: cardType.imageURL,
                imageName,
                showImageDialog: true
              })
            }}
          >
            <Document
              file={cardType.imageURL}
              loading="Avataan PDF..."
              noData="Tiedostoa ei löytynyt"
              error="Tiedoston avaus epäonnistui"
            >
              <Page
                pageNumber={1}
                width={100}
                height={80}
              />
            </Document>
          </div>
        );
      } else {
        return (
          <div style={{ width: '100px' }}>
            <img
              src={cardType.imageURL}
              onClick={(e) => {
                e.stopPropagation();
                this.setState({
                  imageUrl: cardType.imageURL,
                  imageName,
                  showImageDialog: true
                })
              }}
              style={{
                width: '100px',
                height: '80px',
                objectFit: 'cover',
                cursor: 'pointer',
              }}
            />
          </div>
        );
      }
    }
  }

  toggleColumnVisibilityPreset = (preset) => {
    const { presets, employerUserStore, employerUserStore: { storedColumns } } = this.props;
    const { columns } = this.state;
    const updatedColumns = [...columns];

    let chosenPreset = presets[preset];
    let storePreset = storedColumns[preset]?.name;

    // Match the store and selected preset.
    if (storePreset === preset) {
      chosenPreset = storedColumns[preset].column;
    }

    if (preset === 'all' || chosenPreset) {
      // Toggle column visibility with the selected preset
      updatedColumns.map((column) => {
        if (preset === 'all' || chosenPreset.includes(column.name)) {
          column.options.display = true;
        } else {
          column.options.display = false;
        }
        employerUserStore.setStoredColumns(preset, chosenPreset);
        return column;
      });
      this.setState({
        activePreset: preset,
        columns: updatedColumns,
      });
    }
  }

  closeFilePreviewDialog = () => {
    this.setState({ showImageDialog: false, imageUrl: null, imageName: null, });
  }

  setColumns = (updatedColumns) => {
    this.setState({
      columns: updatedColumns,
    })
  }

  handleRowClick = (rowData, rowMeta) => {
    const { data } = this.state;

    const selectedRowIndex = rowMeta.dataIndex;
    const selectedEmployeeData = data[selectedRowIndex]

    this.setState({
      employeeEditDialogOpen: true,
      selectedEmployee: selectedEmployeeData,
      readOnlyMode: true,
    });
  }

  switchToEdit = () => {
    this.setState({
      readOnlyMode: false,
    });
  }

  render() {
    const {
      data,
      columns,
      activePreset,
      showImageDialog,
      imageUrl,
      imageName,
      employeeEditDialogOpen,
      selectedEmployee,
      readOnlyMode
    } = this.state;
    const { t, hasMargins, presets, csvFilename, employerUserStore, view } = this.props;

    const filename = `${t(`employer_table.columnPresets.${activePreset}`)}_${moment().format('DD-MM-YYYY')}`
    const normalizedFileName = normalizeFileName(filename);

    const options = {
      // filter: activePreset === 'basic' || activePreset === 'clothing' ? true : 'disabled',
      onDownload: (buildHead, buildBody, columns, data) => onDownload(buildHead, buildBody, columns, data),
      onFilterChange: ((_changedColumn, filterList, _type, changedColumnIndex, _displayData) => {
        // We need to update the state columns manually so that the default filters defined there aren't re-applied on re-render
        const updatedColumns = [...columns];
        // changedColumnIndex is null when we're emptying all filters
        if (changedColumnIndex) {
          updatedColumns[changedColumnIndex].options.filterList = filterList[changedColumnIndex];
          employerUserStore.setEmployeesFilters(_changedColumn, filterList[changedColumnIndex])
        } else {
          // When emptying the filters, we also need to empty them from the state
          updatedColumns.forEach((column) => column.options.filterList = null);
          employerUserStore.emptyFilters()
          // Because of the logic we use in these columns set these to empty so they don't reload
          employerUserStore.setEmployeesFilters('currentAccount.active', [])
          employerUserStore.setEmployeesFilters('hasCompletedRegistration', [])
        }

        this.setState({ columns: updatedColumns });
      }),
      onViewColumnsChange: (changedColumn, action) => {
        onViewColumnsChange(changedColumn, action, columns, this.setColumns)
        const columnIndex = columns.findIndex(col => col.name === changedColumn);
        if (columnIndex !== -1) {
          if (action === 'remove') {
            // Remove the changedColumn from the columns array
            employerUserStore.removeStoredColumns(activePreset, [changedColumn]);
          } else if (action === 'add') {
            // Add the changedColumn to the array.
            employerUserStore.setStoredColumns(activePreset, [changedColumn]);
          }
        }
      },
      onRowClick: this.handleRowClick,
      downloadOptions: {
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
        filename: csvFilename || `${normalizedFileName}.csv`,
      },
      draggableColumns: {
        enabled: true,
        transitionTime: 300,
      },
      filterType: 'dropdown',
      enableNestedDataAccess: '.',
      selectableRows: 'none',
      responsive: 'standard',
      tableBodyMaxHeight: '800px',
      textLabels: {
        body: {
          noMatch: "Työntekijöitä ei löytynyt",
        },
        toolbar: {
          search: t(`employer_table.toolbar.search`),
          downloadCsv: t(`employer_table.toolbar.downloadCsv`),
          print: t(`employer_table.toolbar.print`),
          viewColumns: t(`employer_table.toolbar.viewColumns`),
          filterTable: t(`employer_table.toolbar.filterTable`),
        },
        pagination: {
          next: t(`employer_table.pagination.next`),
          previous: t(`employer_table.pagination.previous`),
          rowsPerPage: t(`employer_table.pagination.rowsPerPage`),
          displayRows: t(`employer_table.pagination.displayRows`),
        },
        filter: {
          all: t(`employer_table.filter.all`),
          title: t(`employer_table.filter.title`),
          reset: t(`employer_table.filter.reset`),
        },
        viewColumns: {
          title: t(`employer_table.viewColumns.title`),
          titleAria: t(`employer_table.viewColumns.titleAria`),
        },
        selectedRows: {
          text: t(`employer_table.selectedRows.text`),
          delete: t(`employer_table.selectedRows.delete`),
          deleteAria: t(`employer_table.selectedRows.deleteAria`),
        },
      },
      rowsPerPageOptions: [25, 50, 100],
      rowsPerPage: view === 'work-order-detail' ? 100 : 25,
      // setTableProps: () => {
      //   return {
      //     size: 'small',
      //   }
      // },
    };

    return (
      <div>
        {!data && (
          <GridCell style={{ textAlign: 'center' }}>
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <LoadingSpinner color="black" />
            </div>
          </GridCell>
        )}

        {data && (
          <>
            <div style={{ display: 'inline-block', marginLeft: hasMargins && '20px' }}>
              {Object.keys(presets).map((key) =>  tableSelectionButton(t, key, activePreset, this.toggleColumnVisibilityPreset))}
            </div>
            <div
              className="mui-billing-table"
              style={{ margin: hasMargins && '0px 20px' }}
            >
              <MUIDataTable
                data={data}
                columns={columns}
                options={options}
              />
            </div>
          </>
        )}

        <FilePreviewDialog
          open={showImageDialog}
          fileURL={imageUrl}
          fileName={imageName}
          onClose={this.closeFilePreviewDialog}
        />

        <EmployeeDialog
          open={employeeEditDialogOpen}
          handleClose={this.closeEmployeeEditDialog}
          employee={selectedEmployee}
          readOnlyMode={readOnlyMode}
          switchToEdit={this.switchToEdit}
        />

        {/* <Dialog
          open={showImageDialog}
          onClose={() => {
            this.setState({ showImageDialog: false, imageUrl: null });
          }}
          className="trip-route-dialog"
        >
          <DialogContent className="xl-buttons">
            <div>
              <img style={{ width: '100%' }} src={imageUrl} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              action="close"
              className="employer-cancel-button"
            >
              Sulje
            </Button>
          </DialogActions>
        </Dialog> */}
      </div>
    );
  }
}

export default EmployeesTable;
